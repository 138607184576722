import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Header, Segment, Modal } from 'semantic-ui-react';

import { withApollo } from 'react-apollo';
import Link from '@appComponents/Link';
import Tabs from '@appComponents/Tabs';
import mapModulesToProps from '@utils/mapModulesToProps';
import { routes } from '@constants/routes';
import { ClientPackageForLayoutQuery } from '@graphql/clientPackage/query';
import { isUrlParamValid, digitsOnly } from '@utils/helpers';
import { showModal } from '@utils/modal';
import ClientPackageDuplicateForm from '@modules/client/forms/ClientPackageDuplicate/ClientPackageDuplicateForm';
import ClientPackageDuplicateModel from '@modules/client/forms/ClientPackageDuplicate/ClientPackageDuplicateModel';
import { createForm } from '@utils/forms';

import ClientPackageBackButton from '../components/ClientPackageBackButton';
import ClientPackageEdit from '../views/ClientPackageEdit';
import ContentIndex from '../views/ContentIndex';
import GeoRestrictionsIndex from '../views/GeoRestrictionsIndex';

class ClientPackageLayout extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        clientPackage: PropTypes.object,
        Entity: PropTypes.object,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }),
        match: PropTypes.object,
        Modal: PropTypes.object,
        modal: PropTypes.shape({
            isVisible: PropTypes.bool.isRequired,
        }),
    };

    state = {
        match: this.props.match,
        modal: this.props.modal,
        modalWasClosed: false,
        shouldOpenModal: false,
        loading: false,
    };

    static getDerivedStateFromProps(nextProps, prevState) {
        const urlChanged = nextProps.match.path !== prevState.match.path,
            urlIsNotIndex = nextProps.match.path !== routes.clients.packages.index.path,
            shouldOpenModal = urlChanged && urlIsNotIndex,
            modalChanged = nextProps.modal.isVisible !== prevState.modal.isVisible,
            modalIsNotVisible = !nextProps.modal.isVisible,
            modalWasClosed = modalChanged && modalIsNotVisible;

        return {
            modal: nextProps.modal,
            match: nextProps.match,
            shouldOpenModal: shouldOpenModal,
            modalWasClosed: modalWasClosed,
        };
    }

    componentDidMount() {
        if (this.props.match.params.packagesId === undefined) {
            this.props.Entity.setEntity({
                name: 'clientPackage',
                data: {name: 'Add new package'},
            });
        } else if (!this.props.clientPackage.id && isUrlParamValid(this.props.match.params.id)) {
            this._fetchClientPackage(this.props);
        }

        this.loadModal(this.props.match.path);
    }

    componentDidUpdate(nextProps) {
        if (this.state.shouldOpenModal) {
            this.loadModal(this.props.match.path, this.props.match.params);
        }

        if (
            this.props.clientPackage.id !== undefined &&
            this.props.clientPackage.id !== nextProps.clientPackage.id
        ) {
            this._fetchClientPackage(nextProps);
        }
    }

    _fetchClientPackage = (props) => {
        this.setState(() => ({loading: true}));
        props.client.query({
            query: ClientPackageForLayoutQuery,
            fetchPolicy: 'cache-first',
            variables: {
                id: digitsOnly(props.match.params.packagesId) && props.match.params.packagesId,
            },
        }).then((result) => {
            props.Entity.setEntity({
                name: 'clientPackage',
                data: {
                    client_product: {
                        id: result.data.clientPackage.client_product.id,
                    },
                    name: result.data.clientPackage.name,
                    package: result.data.clientPackage.booking_type,
                },
            });
            this.setState(() => ({loading: false}));
        }).catch(() => {
            props.Modal.setModal({
                isVisible: true,
                content:
                    (<div>
                        <Header icon='warning' color='red' content='Package was not found'/>
                        <Modal.Content>
                            <p>Package was not found</p>
                        </Modal.Content>
                        <Modal.Actions>
                            <Link name='clients.packages.index' params={{id: props.match.params.id}}>
                                Back to packages list
                            </Link>
                        </Modal.Actions>
                    </div>),
            });
        });
    };

    setLoading = (loading) => {
        this.setState(() => ({
            loading: loading,
        }));
    };

    loadModal(path) {
        if (path === routes.clients.packages.duplicate.path) {
            let formParams = {};
            formParams = {
                optionsVariables: {
                    id: this.props.match.params.packagesId,
                    client: this.props.match.params.id,
                }};
            formParams = {...formParams, ...this.props};
            let Form = createForm(ClientPackageDuplicateModel, ClientPackageDuplicateForm, formParams);
            showModal({
                content: <Form/>,
                header: null,
                isVisible: true,
            });
        }
    }

    getClientTabsState() {
        const {match} = this.props;
        let tabs = {},
            active = match.path;

        const geoRestrictionRoutes = routes.clients.packages.geoRestrictions;

        if (match.path === geoRestrictionRoutes.add.path
            || match.path === geoRestrictionRoutes.edit.path
            || match.path === geoRestrictionRoutes.editGeoRestrictions.path) {
            active = geoRestrictionRoutes.index.path;
        }

        if (match.path === routes.clients.packages.content.index.path
            || match.path === routes.clients.packages.content.geoRestrictions.path) {
            active = routes.clients.packages.content.index.path;
        }

        if (match.path === routes.clients.packages.duplicate.path) {
            active = routes.clients.packages.edit.path;
        }

        if (match.path === routes.clients.packages.add.path) {
            tabs = {
                [routes.clients.packages.add.path]: {
                    key: routes.clients.packages.add.path,
                    url: 'clients.packages.add',
                    text: 'General settings',
                    render: <ClientPackageEdit clientId={match.params.id} setLoading={this.setLoading}/>,
                },
            };
        } else {
            tabs = {
                [routes.clients.packages.edit.path]: {
                    key: routes.clients.packages.edit.path,
                    url: 'clients.packages.edit',
                    urlParams: {id: match.params.id, packagesId: match.params.packagesId},
                    text: 'General settings',
                    render: <ClientPackageEdit
                        clientId={match.params.id}
                        setLoading={this.setLoading}
                        packageId={match.params.packagesId}/>,
                },
                [geoRestrictionRoutes.index.path]: {
                    key: geoRestrictionRoutes.index.path,
                    url: 'clients.packages.geoRestrictions.index',
                    urlParams: {id: match.params.id, packagesId: match.params.packagesId},
                    text: 'Geo restrictions',
                    render: <GeoRestrictionsIndex
                        clientId={parseInt(match.params.id, 10)}
                        clientPackageId={parseInt(match.params.packagesId, 10)}
                    />,
                },
                [routes.clients.packages.content.index.path]: {
                    key: routes.clients.packages.content.index.path,
                    url: 'clients.packages.content.index',
                    urlParams: {id: match.params.id, packagesId: match.params.packagesId},
                    text: 'Content',
                    render: <ContentIndex
                        clientId={parseInt(match.params.id, 10)}
                        clientProduct={this.props.clientPackage.client_product}
                        clientPackageId={parseInt(match.params.packagesId, 10)}
                    />,
                },
            };
        }

        return {
            active: active,
            tabs: tabs,
        };
    }

    render() {
        const {active = '', tabs = {}} = this.getClientTabsState();

        return (
            <div>
                <Header as='h3' className='--paddingBottom-14' dividing>
                    {this.props.clientPackage.name}
                    <ClientPackageBackButton clientId={parseInt(this.props.match.params.id, 10)} packageId={parseInt(this.props.match.params.packagesId, 10)}/>
                </Header>
                <Segment basic className='--tabs --clear' loading={this.state.loading}>
                    <Tabs loading={this.state.loading} items={tabs} active={active} level={2}/>
                </Segment>
            </div>
        );
    }
}

const mapDispatchToProps = mapModulesToProps(['Modal', 'Entity']);
const mapStateToProps = (state) => {
    let clientName = '',
        clientPackageName = '',
        clientProductId,
        clientProduct,
        clientProductEventContentVariants;

    if (state.app.entities.clientPackage) {
        if (state.app.entities.clientPackage.name) {
            clientPackageName = state.app.entities.clientPackage.name;
        }

        if (state.app.entities.clientPackage.client_product && state.app.entities.clientPackage.client_product.id) {
            clientProductId = state.app.entities.clientPackage.client_product.id;
        }
    }

    if (state.app.entities.client && state.app.entities.client.name) {
        clientName = state.app.entities.client.name;
    }

    if (clientProductId && state.app.entities.client?.clientProducts?.length) {
        clientProduct = state.app.entities.client.clientProducts.find(product => product.id === clientProductId);

        clientProductEventContentVariants = state.app.entities.client.clientProducts
            .find(product => product.id === clientProductId)?.product.product_event_content_variants;
    }

    return {
        client: {name: clientName},
        clientPackage: {
            client_product: clientProduct,
            name : clientPackageName,
            product_event_content_variants: clientProductEventContentVariants,
        },
        modal: state.modal,
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withApollo(ClientPackageLayout)));
