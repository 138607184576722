import {find as _find, get as _get, has as _has} from 'lodash';
import PropTypes from 'prop-types';

export const sortByTextValue = (data, field = 'text') => {
    if (!Array.isArray(data) || !data.length) {
        return [];
    }

    return [...data].sort((a, b) => {
        if (a[field].toLowerCase() < b[field].toLowerCase()) {
            return -1;
        }

        if (a[field].toLowerCase() > b[field].toLowerCase()) {
            return 1;
        }

        return 0;
    });
};

export const getCountriesFieldsByISO2 = (countriesISO2, countries, field = 'id') => {
    return Object.values(countriesISO2).map(v => {
        return _get(_find(countries, {iso_alpha2_code: v}), field, false);
    });
};

export const sortStringArrayWithoutCaseSensitivity = (sortedArray) => {
    sortedArray.sort((elementA, elementB) => {
        const elA = elementA.toLocaleUpperCase(),
            elB = elementB.toLocaleUpperCase();

        if (elA === elB) {
            return 0;
        } else if (elA < elB) {
            return -1;
        }

        return 1;
    });
};

export const sortByKey = (key) => {
    return function innerSort(a, b) {
        if (!Object.prototype.hasOwnProperty.call(a, key) || !Object.prototype.hasOwnProperty.call(b, key) ) {
            return 0;
        }

        const varA = ('string' === typeof a[key])
            ? a[key].toUpperCase() : a[key];
        const varB = ('string' === typeof b[key])
            ? b[key].toUpperCase() : b[key];

        return varA === varB ? 0 : varA > varB ? 1 : -1;
    };
};

export const sortDropdownOptionsAlphanumerically = (dataToSort) => {
    if ('undefined' === typeof dataToSort || 0 === dataToSort.length) {
        return [];
    }

    return [].concat(dataToSort).sort((a, b) => (
        (!_has(a,'text') || !_has(b,'text'))
            ? 0
            : a.text.localeCompare(b.text, undefined, {numeric: true})
    ));
};

sortDropdownOptionsAlphanumerically.propTypes = {
    dataToSort: PropTypes.shape({
        text: PropTypes.string,
    }),
};
