import moment from "moment";

/**
 * Check if date is between range - start_date / end_date
 * @param date
 * @param datesObject
 * @returns {boolean}
 */
export const checkDateRange = (date, datesObject) => {
    date = transformMomentDatetimeToDate(date);

    return (date.isSameOrAfter(datesObject.start_date) && date.isSameOrBefore(datesObject.end_date))
};

export const transformMomentDatetimeToDate = (date) => {
    const momentObject = !moment.isMoment(date) ? moment(date) : date;

    momentObject.set({
        "hour": 0,
        "minute": 0,
        "second": 0
    });

    return momentObject;
};

export const getTodayDate = () => (
    moment().format('YYYY-MM-DD 00:00')
);

export const getTomorrowDate = () => (
    moment().add(1, 'day').format('YYYY-MM-DD 00:00')
);

export const getSixMonthsAgoDate = () => (
    moment().subtract(6, 'months').format('YYYY-MM-DD 00:00')
);

export const getOneHourAgoDateTime = () => (
    moment().subtract(1, 'hours').format('YYYY-MM-DD HH:mm')
);

export const getLastMinuteOfToday = () => (
    moment().hours(23).minutes(59)
);

export const dateEmpty = (from, to) => !from || !to;
