import {get as _get, isEmpty as _isEmpty} from 'lodash';

import * as CONSTANT from '@constants/variables';

import validators from '../../../utils/validators';

/**
 * Validation for "usage types" field
 *
 * "At least one usage type must be selected by product."
 *
 * Field "usage types" depends on "usages" field.
 *
 * If we check "usages" on the product (with has_usage_type_support=TRUE flag),
 * we need to display this product in "usage types"
 * and make checkbox checked on at least one "usage types" per product
 */
const validateUsageTypesField = (values, products, errors) => {
    // No input data from "usage_types" field
    if (undefined === values.usage_types) {
        return errors;
    }

    const selectedProductUsage = [],
        selectedEventContentVariant = [], //Content variants*
        minSelectedEventContentVariant = []; //Usage*

    /**
     * Get products with has_usage_type_support=TRUE and selected on the form
     * to compare how much "usage types" was selected
     */

    products.forEach((product) => {
        // verify only products with has_usage_type_support flag
        if (product.has_usage_type_support) {
            for (const usage of values.usages) {
                // Selected product "usage" (usage.value) with product.has_usage_type_support = TRUE
                if (usage.value === product.id) {
                    selectedProductUsage.push(product.id);
                }
            }
        }

        if (!_isEmpty(product.product_event_content_variants)) {
            for (const usage of values.usages) {
                if (usage.value === product.id) {
                    minSelectedEventContentVariant.push(product.id);
                }
            }

            for (const variant of values.content_variants) {
                if (variant.value === product.id) {
                    selectedEventContentVariant.push(product.id);

                    return;
                }
            }
        }
    });

    /**
     * Count of selected "usage" with has_usage_type_support=TRUE
     * must be the same as count of selected "usage type"
     */
    if (selectedProductUsage.length !== values.usage_types.length) {
        Object.assign(errors, {'usage_types': 'At least one usage type must be selected by product.'});
    }

    if (selectedEventContentVariant.length !== minSelectedEventContentVariant.length) {
        Object.assign(errors, {'content_variants': 'At least one event content variant must be selected by product.'});
    }

    return errors;
};

const validate = (values, props) => {
    const errors = {},
        products = _get(props.GraphQLOptionsData, 'products', []);

    Object.assign(errors, validators.required(values.licensee, 'licensee'));
    Object.assign(errors, validators.required(values.usages, 'usages'));
    Object.assign(errors, validators.required(values.territory_selection_type, 'territory_selection_type'));
    Object.assign(errors, validators.required(values.start_date, 'start_date'));
    Object.assign(errors, validators.required(values.end_date, 'end_date'));
    Object.assign(errors, validators.date(values.start_date, 'start_date'));
    Object.assign(errors, validators.date(values.end_date, 'end_date'));

    if (false === values.includes_all_content) {
        Object.assign(errors, validators.required(values.contents, 'contents'));
    }

    if (CONSTANT.TERRITORY_SELECTION_TYPE_WORLDWIDE !== parseInt(values.territory_selection_type)) {
        Object.assign(errors, validators.required(values.countries, 'countries'));
    }

    if (new Date(values.start_date) > new Date(values.end_date)) {
        Object.assign(errors, {end_date: 'End date must be later than the Start date'});
    }

    validateUsageTypesField(values, products, errors);

    return errors;
};

export default validate;
