import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';

import {renderLinkToElementPage} from '@modules/changelog/utils/changelogHelper';
import {getSixMonthsAgoDate, getTomorrowDate} from '@utils/date';

const ChangelogElementDetails = (props) => {
    const {
        resourceID,
        elementID,
        changelogElementData: {
            secured_resource,
            element_id,
            element_name,
            creationUser,
            deletionUser,
            creationDateTime,
            deletionDateTime,
            path,
        } = {},
    } = props;

    const startDatetime = creationDateTime ? creationDateTime : getSixMonthsAgoDate();
    const url = `/changelog?resources=["${resourceID}"]&elementId=${elementID}&startDatetime=${startDatetime}&endDatetime=${getTomorrowDate()}`;

    return (
        <>
            <div className='form content'>
                <div className='formRow'>
                    <label className='--text-left'>Resource</label>
                    {secured_resource.name || ''}
                </div>
                <div className='formRow'>
                    <label className='--text-left'>Element ID</label>
                    {element_id || null}
                </div>
                <div className='formRow'>
                    <label className='--text-left'>Element name</label>
                    {element_name || ''}
                </div>
                {path &&
                    <div className='formRow'>
                        <label className='--text-left'>Link</label>
                        {renderLinkToElementPage(props.changelogElementData, true)}
                    </div>
                }
                <div className='formRow'>
                    <label className='--text-left'>Created on</label>
                    {`${creationDateTime || '-'} ${creationUser.name ? 'by ' : ''}`}
                    <Link className='--marginLeft-0-3-rem' target='_blank' to={{pathname: `/users/${creationUser.id}`}}>
                        {creationUser.name}
                    </Link>
                </div>
                {deletionDateTime &&
                    <div className='formRow'>
                        <label className='--text-left'>Deleted on</label>
                        {`${deletionDateTime} ${deletionUser.name ? 'by ' : ''}`}
                        <Link className='--marginLeft-0-3-rem' target='_blank' to={{pathname: `/users/${deletionUser.id}`}}>
                            {deletionUser.name}
                        </Link>
                    </div>
                }
                <div className='formRow --marginTop-10'>
                    <Link
                        target='_blank'
                        to={{pathname: url}}
                    >
                        See also the revision history for this element.
                    </Link>
                </div>
            </div>
        </>
    );
};

ChangelogElementDetails.defaultProps = {
    resourceID: null,
    elementID: null,
    changelogElementData: {},
};

ChangelogElementDetails.propTypes = {
    resourceID: PropTypes.string,
    elementID: PropTypes.string,
    changelogElementData: PropTypes.object,
};

export default ChangelogElementDetails;
