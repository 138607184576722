import {get as _get, has as _has} from 'lodash';

import {PRODUCT_LCO, RTS_COVERAGE_CONFIRMED} from '@constants/variables';
import {getSportNameFromEvent} from '@utils/sport';
import {getTournamentCategoryNameFromEvent, getTournamentNameFromEvent} from '@utils/tournament';
import {isValidMatchStatusId} from '@modules/liveControl/utils/helpers';

const getPropertyNameHandler = (event, fn, propertyName) => {
    let result = fn(event);

    // @TODO: properly refactor getTournamentCategoryNameFromEvent, getTournamentNameFromEvent, getSportNameFromEvent
    // methods to get rid of annoying minus
    if ('-' === result) {
        result = _get(event, propertyName, '-');
    }

    return result;
};

export const checkIfIsEvent = (event) => (_has(event, 'sr_event.id') || _has(event, 'av_event.id'));

export const checkIfIsMasterMatch = (event) => (_has(event, 'master_match_tournaments'));

export const checkIfIsTvStreaming = (event) => {
    const path = checkIfIsEvent(event) ? 'sr_event.match.is_tv_streaming' : 'is_tv_streaming';

    return Boolean(_get(event, path, false));
};

export const getSportName = (event) => (
    getPropertyNameHandler(event, getSportNameFromEvent, 'av_event.content_category_level1.name')
);

export const getTournamentCategoryName = (event) => (
    getPropertyNameHandler(event, getTournamentCategoryNameFromEvent, 'av_event.content_category_level2.name')
);

export const getTournamentName = (event) => (
    getPropertyNameHandler(event, getTournamentNameFromEvent, 'av_event.content_category_level3.name')
);

export const getTvStreamingEncoderId = (event) => {
    const path = checkIfIsEvent(event) ? 'sr_event.match.tv_streaming_encoder.id' : 'tv_streaming_encoder.id';

    return _get(event, path, null);
};

export const getCompetitorWarnings = (event) => {
    const {match_status_id, match_status_name} = getMatchStatusData(event);
    let competitorWarnings = [],
        match = event;

    if (checkIfIsEvent(event) && _has(event, 'sr_event.match.id')) {
        match = event.sr_event.match;
    } else if (checkIfIsEvent(event) || checkIfIsMasterMatch(event)) {
        return competitorWarnings;
    }

    if (!_has(match, 'home_competitor.id')) {
        competitorWarnings.push('No unique home competitor');
    }

    if (!_has(match, 'away_competitor.id')) {
        competitorWarnings.push('No unique away competitor');
    }

    if (_has(match, 'rts_coverage.id') && parseInt(match.rts_coverage.id, 10) === RTS_COVERAGE_CONFIRMED) {
        competitorWarnings.push('RTS');
    }

    if (!match.has_live_scout) {
        competitorWarnings.push('No scout');
    }

    if (!match.has_live_odds) {
        competitorWarnings.push('No Live Odds');
    }

    if (match.is_cancelled) {
        competitorWarnings.push('Cancelled');
    }

    if (match.is_postponed) {
        competitorWarnings.push('Postponed');
    }

    if (match.is_walkover) {
        competitorWarnings.push('Walkover');
    }

    if (isValidMatchStatusId(match_status_id)) {
        competitorWarnings.push(match_status_name);
    }

    return competitorWarnings;
};

export const getMatchStatusData = (data) => {
    if (_has(data, 'sr_event.match')) {
        return {
            match_status_id: data.sr_event.match?.match_status_id,
            match_status_name: data.sr_event.match?.match_status_name,
        };
    }

    return {
        match_status_id: data.match_status_id || null,
        match_status_name: data.match_status_name || null,
    };
};

export const isHomeCompetitorBlacklisted = (event) => {
    return Boolean(_get(event, 'sr_event.match.home_competitor.is_blacklisted',
        _get(event, 'sr_event.home_competitor.is_blacklisted',
            _get(event, 'home_competitor.is_blacklisted', false)
        )
    ));
};

export const isAwayCompetitorBlackListed = (event) => {
    return Boolean(_get(event, 'sr_event.match.away_competitor.is_blacklisted',
        _get(event, 'sr_event.away_competitor.is_blacklisted',
            _get(event, 'away_competitor.is_blacklisted', false)
        )
    ));
};

export const filteredSelectedTournamentCategory = (selectedSports, prevState, setState) => {
    if (selectedSports && prevState.selectedSports && selectedSports !== prevState.selectedSports) {
        setState(prevState => ({
            selectedTournamentCategories: prevState.selectedTournamentCategories
                ?.filter(selectedTournamentCategory => prevState.tournamentCategories
                    .some(tournamentCategory => tournamentCategory.value === selectedTournamentCategory)),
        }));
    }
};

export const filteredSelectedTournament = (prevState, setState, tournamentsState, selectedTournaments) => {
    if (tournamentsState !== prevState.tournaments) {
        setState(() => ({
            selectedTournaments: selectedTournaments
                ?.filter(selectedTournament => tournamentsState
                    .some(item =>item.value === selectedTournament)),
        }));
    }
};

export const allEventsHasLcoDistribution = (selectedEventsData) => {
    return selectedEventsData.every(event =>
        event.event_contents.some(content =>
            content.products.some(product => PRODUCT_LCO === product.product_id)
        )
    );
};
