export const propertyLicenceEventsQuery = `
  query PropertyLicenceEventsQuery (
    $search: String
    $propertyLicence: Int!
    $from: DatetimeTzInputType
    $to: DatetimeTzInputType
    $tournament: [Int]
    $contentCategoryLevel3: [Int]
    $eventStatus: [Int]
    $product: [Int]
    $originStreamStatus: [Int]
    $issueTypes: [Int]
  ) {
    propertyLicenceEvents (
      propertyLicence: $propertyLicence
      filters: {
        search: $search
        from: $from
        to: $to
        tournament: $tournament
        contentCategoryLevel3: $contentCategoryLevel3
        eventStatus: $eventStatus
        product: $product
        originStreamStatus: $originStreamStatus
        issueTypes: $issueTypes
  	  }
    ) {
      id
      start_datetime
      end_datetime
      description
      color
      event_type {
        id
        name
      }
      property_licence_type {
        id
        name
      }
      origin_stream_status {
        id
        name
      }
      comment
      event_issue_types {
        id
        name 
       }
      event_status {
        id
        name
      }
      products_invoice_status {
        product_id: id
        name
        short_name
        has_usage
        invoice_status {
          id
          name
        }
      }
      bookings
      sr_event {
        id
        tournament {
          id
          name
          tournament_category {
            id
            name
          }
        }
        home_competitor {
          id
          name
        }
        away_competitor {
          id
          name
        }
        match {
          id
          tournament {
            id
            name
            tournament_category {
              id
              name
            }
          }
          home_competitor {
            id
            name
          }
          away_competitor {
            id
            name
          }
        }
      }
      av_event {
        id
        content_category_level2 {
          id
          name
        }
        content_category_level3 {
          id
          name
        }
        home_av_competitor {
          id
          name
        }
        away_av_competitor {
          id
          name
        }
      }
      event_contents {
        id
        products {
          product_id
          short_name
        }
      }
    }
  }
`;

export const propertyLicenceEventsFiltersQuery = `
  query PropertyLicenceEventsFiltersQuery (
    $propertyLicence: ID!
  ) {
    propertyLicenceContents (filters: {
      propertyLicence: $propertyLicence
    }) {
      tournament {
        id
        name
        tournament_category {
          id
          name
        }
      }
      content_category {
        id
        name
        parent {
          id
          name
        }
      }
    }
    eventStatuses {
      key: id
      text: name
      value: id
    }
    products {
      key: id
      text: short_name
      value: id
    }
    originStreamStatuses {
      key: id
      text: name
      value: id
    }
    eventIssueTypes {
    key: id
    text: name
    value: id
  }
  }
`;
