import * as MESSAGES from '@constants/messages';
import Form from '@appComponents/ReduxFormControls';
import {getClientPackage, DuplicateClientPackage} from '@graphql/clientPackage/query';
import {CLIENT_PACKAGE} from '@constants/resources';

import validate from './../validatorClientPackageDuplicate';

export default function ClientPackageDuplicateModel() {
    return {
        entityDataMapKey: 'duplicateClientPackage',
        entityLabel: 'Client Package Duplicate',
        formName: 'duplicateClientPackage',
        label: 'duplicateClientPackage',
        resources: CLIENT_PACKAGE,
        showChangeLog: false,
        title: null,
        editForm: false,
        validate: validate,
        dataMap: {
            duplicateClientPackage: {
                id: '',
                name: '',
                price_option: '',
                value: '',
            },
        },
        messages: {
            boxName: {
                delete: 'clientPackageContent',
                error: 'clientPackageContent',
                success: 'clientPackageContent',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: () => 'The client package could not be duplicated',
                SAVED: () => 'The client package has been duplicated successfully',
            },
        },
        fields: {
            id: {
                props: {
                    component: Form.IdField,
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            name: {
                props: {
                    component: Form.SemanticInput,
                    label: 'Name',
                    name: 'name',
                    required: true,
                },
            },
            price_option: {
                props: {
                    component: Form.SemanticSelect,
                    label: 'Prices',
                    name: 'price_option',
                    required: false,
                    allowClear: true,
                    options: [
                        {text: 'Percentage increase', value: 'increase_price'},
                        {text: 'Percentage decrease', value: 'decrease_price'},
                        {text: 'Remove all prices', value: 'remove_price'},
                    ],
                    placeholder: 'Keep the same prices',
                },
            },
            value: {
                props: {
                    component: Form.SemanticInput,
                    label: 'Value',
                    name: 'value',
                    hidden: true,
                    required: false,
                    description: ' %',
                },
            },
        },
        mutation: {
            createEntity: DuplicateClientPackage,
        },
        query: {
            getOptions: getClientPackage,
        },
    };
}
