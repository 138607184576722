import {get as _get, isEmpty as _isEmpty} from 'lodash';

import DefaultForm from '@appComponents/DefaultForm';
import {hasPrivileges} from '@appComponents/Authorization';
import {onSubmit} from '@utils/forms';
import {convertToInt} from '@utils/helpers';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {PROPERTY_LICENCE_TECH_SETUP} from '@constants/resources';
import {
    ENCODER_TYPE_MANUAL,
    ENCODER_TYPE_SPORTRADAR,
    ORIGIN_STREAM_TYPE_RTMP,
    STRING_DEFAULT_SCOREBOARD_OVERLAY_POSITION,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import {CreatePropertyLicenceTechSetupManual} from '@graphql/propertyLicenceTechSetupManual/mutation';
import {GetPropertyLicenceTechSetupEditFormOptions} from '@graphql/propertyLicenceTechSetup/query';

export class TechnicalSetupForm extends DefaultForm {
    prepareGeneralData = (data) => ({
        property_licence: this.props.formParams.propertyLicenceId,
        contribution_type: convertToInt(data.contribution_type) || null,
        encoding_datacenter: convertToInt(data.encoding_datacenter) || null,
        encoding_redundancy_type: convertToInt(data.encoding_redundancy_type) || null,
        origin_stream_type: convertToInt(data.origin_stream_type) || null,
        is_tv_streaming: data.is_tv_streaming || false,
        encoder_type: convertToInt(data.encoder_type),
        client_cdn_configurations: data.client_cdn_configurations ? data.client_cdn_configurations.map(Number) : [],
    });

    prepareSrData = (data) => ({
        has_scoreboard_overlay_support: !!data.has_scoreboard_overlay_support,
        start_encoding_with_scoreboard_overlay: data.has_scoreboard_overlay_support
            ? !!data.start_encoding_with_scoreboard_overlay
            : null,
        scoreboard_overlay_position: data.has_scoreboard_overlay_support
            ? convertToInt(data.scoreboard_overlay_position)
            : null,
        has_slate_support: !!data.has_slate_support,
        slate: data.has_slate_support ? (convertToInt(data.slate) || null) : null,
        has_overlay_support: !!data.has_overlay_support,
        start_encoding_with_overlay: data.has_overlay_support ? data.has_overlay_support : null,
        overlay: data.has_overlay_support ? convertToInt(data.overlay || null) : null,
        has_ad_support: !!data.has_ad_support,
        ad_type: data.has_ad_support ? convertToInt(data.ad_type) : null,
        has_drm_support: !!data.has_drm_support,
        has_cc: !!data.has_cc,
        cc_stream_language: (data.has_cc && data.stream_language) ? data.stream_language : null,
        client_drm_setups: (data.has_drm_support && data.drm_clients) ? data.drm_clients.map(Number) : [],
        akamai_rtmp_distribution_regions: data.akamai_rtmp_distr_regions,
        client_product_third_parties: data.client_product_third_parties ? data.client_product_third_parties.map(Number) : [],
    });

    prepareData = (data) => {
        const generalData = this.prepareGeneralData(data),
            manualData = (ENCODER_TYPE_MANUAL === convertToInt(data.encoder_type))
                ? {playback_path: data.playback_path || null}
                : null,
            srData = (ENCODER_TYPE_SPORTRADAR === convertToInt(data.encoder_type))
                ? this.prepareSrData(data)
                : null;
        let result = {
            ...manualData,
            ...srData,
            property_licence_tech_setup: generalData,
        };

        if (0 < convertToInt(this.props.formValues.id)) {
            result = {
                id: convertToInt(data.id),
                ...generalData,
                property_licence_tech_setup_manual: manualData,
                property_licence_tech_setup_sr: srData,
            };
        }

        return result;
    };

    prepareDataForSubmit = (data) => (
        Object.assign(
            {},
            this.props.Model.dataMap[this.props.Model.entityDataMapKey],
            data ? this.prepareData(data) : {}
        )
    );

    onFormSubmit(data = {}) {
        if (ORIGIN_STREAM_TYPE_RTMP !== convertToInt(data.origin_stream_type)) {
            data.is_tv_streaming = false;
        }

        if (!this.props.formValues.id && ENCODER_TYPE_MANUAL === convertToInt(data.encoder_type)) {
            const dataToSave = this.prepareDataForSubmit(data);

            return onSubmit({
                dataToSave,
                actions: {create: {mutation: CreatePropertyLicenceTechSetupManual}},
                message: {
                    box: this.props.MessageBox,
                    boxName: this.props.Model.messages.boxName,
                    entityLabel: this.props.Model.entityLabel,
                    entityName: '',
                    text: this.props.Model.messages.text,
                },
                callback: {
                    created: (createdData) => {
                        this.state.defaultForm_callback.created(createdData);
                    },
                    updated: (updatedData) => {
                        this.state.defaultForm_callback.updated(updatedData);
                    },
                },
                apolloClient: this.props.client,
            });
        }

        return super.onFormSubmit(data);
    }

    setEditFormOptions = (technicalSetupId) => {
        this.props.client.query({
            fetchPolicy: 'network-only',
            query: GetPropertyLicenceTechSetupEditFormOptions,
            variables: {propertyLicenceTechSetup: technicalSetupId},
        }).then((response) => {
            this.setField('slate', {options: response.data.slates});
            this.setField('overlay', {options: response.data.overlays});
        });
    };

    checkSrSavedFields = (techSetupSr) => {
        const authorizedToUpdate = hasPrivileges({[PROPERTY_LICENCE_TECH_SETUP]: SECURITY_PRIVILEGES_UPDATE}),
            disabled = techSetupSr.disabled || !authorizedToUpdate;

        this.setField('has_scoreboard_overlay_support', {hidden: false, disabled: disabled});
        this.setField('start_encoding_with_scoreboard_overlay', {hidden: !techSetupSr.has_scoreboard_overlay_support, disabled: disabled});
        this.setField('scoreboard_overlay_position', {hidden: !techSetupSr.has_scoreboard_overlay_support, disabled: disabled});
        this.setField('has_slate_support', {hidden: false, disabled: disabled});
        this.setField('slate', {hidden: !techSetupSr.has_slate_support, disabled: disabled});
        this.setField('has_overlay_support', {hidden: false, disabled: disabled});
        this.setField('overlay', {hidden: !techSetupSr.has_overlay_support, disabled: disabled});
        this.setField('has_ad_support', {hidden: false, disabled: disabled});
        this.setField('ad_type', {hidden: !techSetupSr.has_ad_support, disabled: disabled});
        this.setField('has_drm_support', {hidden: false, disabled: disabled});
        this.setField('drm_clients', {hidden: !techSetupSr.has_drm_support, disabled: disabled});
        this.setField('akamai_rtmp_distr_regions', {hidden: false, disabled: disabled});
        this.setField('client_product_third_parties', {hidden: false, disabled: disabled});
        this.setField('has_cc', {hidden: false, disabled: disabled});
        this.setField('stream_language', {hidden: !techSetupSr.has_cc, disabled: disabled});
    };

    getData = () => {
        let data = super.getData();

        if (!_isEmpty(this.state.defaultForm_dataAfterMutation)) {
            data = this.state.defaultForm_dataAfterMutation;
        }

        return data;
    };

    setDefaultDataForSubmit = (encoderTypeId) => {
        if (ENCODER_TYPE_SPORTRADAR === encoderTypeId) {
            this.setField('playback_path', {defaultValue: ''});
        } else if (ENCODER_TYPE_MANUAL === encoderTypeId) {
            this.setField([
                'has_scoreboard_overlay_support',
                'start_encoding_with_scoreboard_overlay',
                'has_slate_support',
            ], {defaultValue: true});
            this.setField([
                'has_overlay_support',
                'has_ad_support',
                'has_drm_support',
            ], {defaultValue: false});
            this.setField([
                'drm_clients',
                'akamai_rtmp_distr_regions',
                'client_product_third_parties',
            ], {defaultValue: []});
            this.setField([
                'slate',
                'overlay',
                'ad_type',
            ], {defaultValue: null});
            this.setField(
                'scoreboard_overlay_position',
                {defaultValue: STRING_DEFAULT_SCOREBOARD_OVERLAY_POSITION}
            );

            this.resetFieldToDefault('akamai_rtmp_distr_regions');
        }
    };

    componentDidMount() {
        this.setOnChangeCallback({
            encoder_type: (data) => {
                const isSportradarEncoderType = ENCODER_TYPE_SPORTRADAR === convertToInt(data.value),
                    isManualEncoderType = ENCODER_TYPE_MANUAL === convertToInt(data.value);

                this.setField('has_scoreboard_overlay_support', {hidden: isManualEncoderType});
                this.setField('start_encoding_with_scoreboard_overlay', {
                    hidden: (isManualEncoderType || !this.props.formValues.has_scoreboard_overlay_support),
                });
                this.setField('scoreboard_overlay_position', {
                    hidden: (isManualEncoderType || !this.props.formValues.has_scoreboard_overlay_support),
                });
                this.setField('has_slate_support', {hidden: isManualEncoderType});
                this.setField('slate', {
                    hidden: (isManualEncoderType || !this.props.formValues.has_slate_support),
                });
                this.setField('has_overlay_support', {hidden: isManualEncoderType});
                this.setField('overlay', {
                    hidden: (isManualEncoderType || !this.props.formValues.has_overlay_support),
                });
                this.setField('has_ad_support', {hidden: isManualEncoderType});
                this.setField('ad_type', {
                    hidden: (isManualEncoderType || !this.props.formValues.has_ad_support),
                });
                this.setField('has_drm_support', {hidden: isManualEncoderType});
                this.setField('drm_clients', {
                    hidden: (isManualEncoderType || !this.props.formValues.has_drm_support),
                });
                this.setField('akamai_rtmp_distr_regions', {hidden: isManualEncoderType});
                this.setField('client_product_third_parties', {hidden: isManualEncoderType});
                this.setField('playback_path', {hidden: isSportradarEncoderType});
                this.setField('has_cc', {hidden: isManualEncoderType});
                this.setField('stream_language', {hidden: isManualEncoderType || !this.props.formValues.has_cc});
            },
            has_scoreboard_overlay_support: (data) => {
                this.setField('has_scoreboard_overlay_support', {defaultValue: !data.value});
                this.setField('start_encoding_with_scoreboard_overlay', {hidden: data.value});
                this.setField('scoreboard_overlay_position', {hidden: data.value});
            },
            start_encoding_with_scoreboard_overlay: (data) => {
                this.setField('start_encoding_with_scoreboard_overlay', {defaultValue: !data.value});
            },
            has_slate_support: (data) => {
                this.setField('has_slate_support', {defaultValue: !data.value});
                this.setField('slate', {hidden: data.value});
            },
            has_overlay_support: (data) => {
                this.setField('has_overlay_support', {defaultValue: !data.value});
                this.setField('start_encoding_with_overlay', {defaultValue: !data.value});
                this.setField('overlay', {hidden: data.value});
            },
            has_ad_support: (data) => {
                this.setField('has_ad_support', {defaultValue: !data.value});
                this.setField('ad_type', {hidden: data.value});
            },
            has_drm_support: (data) => {
                this.setField('has_drm_support', {defaultValue: !data.value});
                this.setField('drm_clients', {hidden: data.value});
            },
            playback_path: (data) => {
                this.setField('playback_path', {defaultValue: data.value});
            },
            has_cc: (data) => {
                this.setField('stream_language', {hidden: data.value});
            }
        });
        this.setCreateSuccessCallback((response) => {
            const techSetup = _get(response, 'data.createPropertyLicenceTechSetupSr', null)
                || _get(response, 'data.createPropertyLicenceTechSetupManual', null),
                data = {
                    ...techSetup.property_licence_tech_setup,
                    property_licence_tech_setup_manual: {...techSetup},
                    property_licence_tech_setup_sr: {...techSetup},
                };

            this.setField('id', {defaultValue: techSetup.property_licence_tech_setup.id});
            this.setState(() => ({defaultForm_dataAfterMutation: {technicalSetups: [data]}}));
            this.setDefaultDataForSubmit(convertToInt(_get(data, 'encoder_type.id', null)));
            this.props.Entity.setEntity({name: 'propertyLicenceTechSetup', data: Object.assign({}, data)});

            this.setState(() => ({
                defaultForm_data: {
                    variables: {id: techSetup.property_licence_tech_setup.property_licence.id},
                },
            }));

            if (ENCODER_TYPE_SPORTRADAR === convertToInt(techSetup.property_licence_tech_setup.encoder_type.id)) {
                this.checkSrSavedFields(techSetup);
            }
        });
        this.setUpdateSuccessCallback((response) => {
            const techSetup = _get(response, 'data.updatePropertyLicenceTechSetup', null);

            this.setState(() => ({defaultForm_dataAfterMutation: {technicalSetups: [techSetup]}}));
            this.setDefaultDataForSubmit(convertToInt(_get(techSetup, 'encoder_type.id', null)));
        });
    }

    componentDidUpdate() {
        super.componentDidUpdate();

        const {GraphQLOptionsData: {encoderTypes}} = this.props,
            options = _get(this.props, 'GraphQLOptionsData', {}),
            technicalSetup = _get(this.props.GraphQLEntityData, 'technicalSetups[0]', {}),
            encoderTypeId = convertToInt(_get(technicalSetup, 'encoder_type.id', null));

        if (!this.state.areOptionsGenerated && !_isEmpty(encoderTypes)) {
            if ((null === this.props.formParams.technicalSetupId) || (ENCODER_TYPE_MANUAL === encoderTypeId)) {
                this.setField([
                    'has_scoreboard_overlay_support',
                    'start_encoding_with_scoreboard_overlay',
                    'has_slate_support',
                ], {defaultValue: true});
            }

            this.setField('drm_clients', {
                options: sortDropdownOptionsAlphanumerically(options.clientDrmSetups.map((option) => ({
                    ...option,
                    text: `${option.client.name}`,
                }))),
            });

            this.setField('client_product_third_parties', {
                options: options.clientProductsThirdParty.map((option) => ({
                    ...option,
                    text: `${option.client_product.client.name}`,
                })),
            });

            this.setField('client_cdn_configurations', {
                options: options.clientCdnConfigurations.map((option) => ({
                    ...option,
                    text: `${option.client_product.client.name} - ${option.client_product.product.short_name} - ${option.name}`,
                })),
            });

            this.setField('stream_language', {
                options: options.streamLanguages.map((option) => {
                    const languageCodeString = option.language.code ? `(${option.language.code})` : '';
                    const text = `${option.name} ${languageCodeString}`;

                    return {
                        id: option.id,
                        key: option.id,
                        value: option.id,
                        text,
                    };
                }),
            });

            this.setState(() => ({areOptionsGenerated: true}));
        }

        if (!this.state.isSavedDataGenerated && !_isEmpty(technicalSetup)) {
            this.setEditFormOptions(convertToInt(technicalSetup.id));

            if (ENCODER_TYPE_SPORTRADAR === encoderTypeId) {
                this.checkSrSavedFields(technicalSetup.property_licence_tech_setup_sr);

                this.setField('drm_clients', {
                    defaultValue: technicalSetup.property_licence_tech_setup_sr.client_drm_setups
                        .map((item) => item.id),
                });

                this.setField('akamai_rtmp_distr_regions', {
                    defaultValue: technicalSetup.property_licence_tech_setup_sr.akamai_rtmp_distribution_regions
                        .map((item) => convertToInt(item.id)),
                });

                this.setField('client_product_third_parties', {
                    defaultValue: technicalSetup.property_licence_tech_setup_sr.client_product_third_parties
                        .map((item) => item.id),
                });

                this.setField('scoreboard_overlay_position', {
                    defaultValue: _get(
                        technicalSetup,
                        'property_licence_tech_setup_sr.scoreboard_overlay_position.id',
                        STRING_DEFAULT_SCOREBOARD_OVERLAY_POSITION
                    ),
                });

            } else if (ENCODER_TYPE_MANUAL === encoderTypeId) {
                this.setField('playback_path', {hidden: false});
            }

            this.setField('client_cdn_configurations', {
                defaultValue: technicalSetup.client_cdn_configurations
                    .map((item) => item.id),
            });

            this.setState(() => ({isSavedDataGenerated: true}));
        }
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Save'})
    );

    renderCancelButton = () => null;

    renderDeleteButton = () => null;
}

export default TechnicalSetupForm;
