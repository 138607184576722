import Form from '@appComponents/ReduxFormControls';
import * as MESSAGES from '@constants/messages';
import {SECURITY_PRIVILEGES_READ_UPDATE} from '@constants/variables';
import {USER_SETTINGS} from '@constants/resources';
import {GetUserSettingsForForm} from '@graphql/user/query';
import {UpdateUserSettings} from '@graphql/user/mutation';

import {renderFormErrorMessages, renderSuccessMessage} from '../utils/modelHelper';

export default function UserSettingsModel() {
    return {
        dataMap: {
            userSettings: {
                autodetected_timezone: '',
                detected_country: '',
                detected_ip: '',
                login_ip: '',
                name: '',
                iam_user: {
                    nickname: '',
                },
                timezone: '',
            },
            timezones: [],
        },
        entityDataMapKey: 'userSettings',
        entityName: 'User Settings',
        fields: {
            id: {
                component: Form.IdField,
                dataMapKey: 'userSettings.id',
                props: {
                    hidden: true,
                    label: '',
                    name: 'id',
                },
            },
            email: {
                dataMapKey: 'userSettings.email',
                props: {
                    component: Form.TextPlaceholder,
                    label: 'Email',
                    name: 'email',
                    size: 'small',
                    type: 'input',
                },
            },
            name: {
                dataMapKey: 'userSettings.name',
                props: {
                    component: Form.TextPlaceholder,
                    label: 'Name',
                    name: 'name',
                    type: 'text',
                },
            },
            timezone: {
                dataMapKey: 'userSettings.timezone',
                props: {
                    component: Form.SemanticSelect,
                    label: 'Time zone',
                    name: 'timezone',
                    placeholder: 'Select timezone',
                },
            },
            ip: {
                dataMapKey: 'userSettings.ip',
                props: {
                    component: Form.TextPlaceholder,
                    className: '--align-baseline',
                    label: 'IP',
                    name: 'ip',
                },
            },
        },
        formName: 'userSettings',
        label: 'User Settings',
        messages: {
            boxName: {
                error: 'userSettings',
                success: 'userSettings',
            },
            text: {
                ...MESSAGES,
                FORM_ERROR: renderFormErrorMessages,
                SAVED: renderSuccessMessage,
            },
        },
        mutation: {
            updateEntity: UpdateUserSettings,
        },
        query: {
            getEntity: GetUserSettingsForForm,
        },
        resources: USER_SETTINGS,
        authorization: {
            [USER_SETTINGS]: SECURITY_PRIVILEGES_READ_UPDATE,
        },
        title: null,
    };
}
