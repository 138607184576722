import {get as _get} from 'lodash';
import {withRouter} from 'react-router-dom';

import {getLink} from '@appComponents/Link';
import {convertToInt} from '@utils/helpers';
import {DuplicateClientPackage} from '@graphql/clientPackage/query';
import DefaultForm from '@appComponents/DefaultForm';

class ClientPackageDuplicateForm extends DefaultForm {

    createLink = (link) => {
        this.props.history.push(getLink(link, {
            id: this.props.formParams.optionsVariables.client,
            packagesId: this.props.formParams.optionsVariables.id,
        }));
    }

    componentDidMount() {
        this.setCreateSuccessCallback(() => {
            this.createLink();
        });

        this.setOnChangeCallback({
            price_option: (event) => {
                this.setField(
                    'value',
                    {
                        hidden: ('' === event.value || 'remove_price' === event.value),
                        required: !('' === event.value || 'remove_price' === event.value),
                    }
                );
            },
        });
    }

    onCancel = () => {
        this.createLink('clients.packages.edit');
    };

    renderCancelButton = (props) => super.renderCancelButton({...props, type: 'button', onClick: this.onCancel});

    componentDidUpdate() {
        super.componentDidUpdate();

        let clientPackage = _get(this.props.GraphQLOptionsData, 'clientPackage', null);

        if (clientPackage && !this.state.dataLoaded) {
            this.setField('id', {defaultValue: clientPackage.id});
            this.setField('name', {defaultValue: `Copy of ${clientPackage.name}`});
            this.setState(() => ({
                defaultForm_formTitle: `Duplicate package "${clientPackage.name}"`,
                dataLoaded: true,
            }));
        }
    }

    onFormSubmit(data) {
        this.setState(() => ({
            defaultForm_loading: true,
        }));

        return this.runApolloRequest('mutate', {
            mutation: DuplicateClientPackage,
            variables: {
                id: convertToInt(data.id),
                name: data.name,
                price_option: data.price_option ? data.price_option : 'keep_price',
                value: data.value ? parseFloat(data.value) : null,
            },
        }).then(() => {
            this.props.Modal.setModal({isVisible: false});
            this.createLink('clients.packages.index');
            this.props.MessageBox.addMessage(
                'clientMessage',
                'The client package has been duplicated successfully',
                null,
                'success'
            );
        }).catch((error) => {
            this.props.Modal.setModal({isVisible: false});
            this.createLink('clients.packages.edit');
            this.props.MessageBox.addMessage(
                'clientMessage',
                'The client package could not be duplicated',
                `${error.message}`,
                'error'
            );
        });
    }

    renderSaveButton = () => (
        super.renderSaveButton({content: 'Duplicate'})
    );

    renderDeleteButton = () => null;
}

export default withRouter(ClientPackageDuplicateForm);
