import React from 'react';
import {get as _get} from 'lodash';
import PropTypes from 'prop-types';

import {columnObjectAlignCenter, columnObjectAlignLeft} from '@utils/tableHelpers';
import {CLIENT_VPL_LICENCE_KEY} from '@constants/resources';
import {SECURITY_PRIVILEGES_READ} from '@constants/variables';
import {IconEdit} from '@appComponents/IconCollection';
import {LinkWithAuthorization} from '@appComponents/LinkWithAuthorization';
import {convertToInt} from '@utils/helpers';

export const columns = {
    'licence_key': {
        label: 'Licence key',
        sorting: row => _get(row, 'licence_key', null),
        ...columnObjectAlignLeft,
    },
    'expiry_date': {
        label: 'Expiry date',
        sorting: true,
        ...columnObjectAlignCenter,
    },
    'domains': {
        label: 'Domains',
        sorting: true,
        ...columnObjectAlignLeft,
    },
    'actions': {
        label: 'Actions',
        sorting: false,
        ...columnObjectAlignCenter,
    },
};
export const rowRenderer = (column, row, {clientId}) => {
    switch (column) {
        case 'licence_key':
            return row.licence_key;
        case 'expiry_date': {
            return new Date(row.expiry_date).toISOString().split('T')[0];
        }
        case 'domains':
            return getClientVplDomains(row.client_vpl_domains);
        case 'actions':
            return (
                <div className='actions-list-icon'>
                    <LinkWithAuthorization
                        authorization={{[CLIENT_VPL_LICENCE_KEY]: SECURITY_PRIVILEGES_READ}}
                        link={{
                            name: 'clients.clientVideoPlayer.edit',
                            params: {id: convertToInt(clientId), clientVplLicenceKeyId: convertToInt(row.id)},
                        }}
                    >
                        <IconEdit resource={CLIENT_VPL_LICENCE_KEY} size='large'/>
                    </LinkWithAuthorization>
                </div>
            );
        default:
            return null;
    }
};
export const getClientVplDomains = (clientVplDomains) => {
    const sortedDomains = [...clientVplDomains].sort((a, b) => a.domain.localeCompare(b.domain));

    return (
        <>
            {sortedDomains.map((domain, index) => (
                <React.Fragment key={domain.id}>
                    <span className={domain.is_disabled ? '--warning' : null}>
                        {domain.domain}
                    </span>
                    {index < sortedDomains.length - 1 && ', '}
                </React.Fragment>
            ))}
        </>
    );
};

export const getTableRowExpiredDateClassName = (row) => {
    const currentDate = new Date();
    const expiryDate = new Date(row.expiry_date);

    const isExpired = expiryDate < currentDate;

    return isExpired ? '--is-disabled' : null;
};


rowRenderer.propTypes = {
    column: PropTypes.string.isRequired,
    row: PropTypes.shape({
        licence_key: PropTypes.string.isRequired,
        expiry_date: PropTypes.string.isRequired,
        client_vpl_domains: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                domain: PropTypes.string.isRequired,
                is_disabled: PropTypes.number.isRequired,
            })
        ).isRequired,
    }).isRequired,
};
