import React from 'react';
import PropTypes from 'prop-types';
import {withApollo} from '@apollo/client/react/hoc';
import moment from 'moment';

import {FiltersWrapper} from '@appComponents/HOCFiltersWrapper';
import {
    onSubmit,
    updateFilterState,
    getMaxMinDate,
    updateDateState,
    handleOnEventTypeChange,
    handleOnSportChange,
    handleOnTournamentCategoryChange,
    handleOnContentCategoryLevel1Change,
    handleOnContentCategoryLevel2Change,
    fetchTournamentCategories,
    fetchTournaments,
    fetchContentCategoryLevel2,
    fetchContentCategoryLevel3,
    fetchSports,
    fetchContentCategoriesLevel1,
} from '@modules/booking/utils/clientBookingsFiltersHelpers';
import {getTodayDate, getTomorrowDate} from '@utils/date';

export function withClientBookingsFilters(WrappedComponent, filterName) {
    class ClientBookingsFiltersHOC extends React.Component {
        static propTypes = {
            additionalFilterId: PropTypes.string,
            client: PropTypes.object,
            data: PropTypes.object.isRequired,
            filters: PropTypes.object.isRequired,
            form: PropTypes.object,
            loading: PropTypes.bool.isRequired,
            restoreFilterValues: PropTypes.func.isRequired,
        };

        static defaultProps = {
            additionalFilterId: null,
        };

        state = {
            fromMaxDate: null,
            fromMaxDateDefault: null,
            fromMaxTime: moment().hours(23).minutes(59),
            toMinDate: null,
            toMinDateDefault: null,
            toMinTime: moment().hours(0).minutes(0),
            selectedContentCategoriesLevel1: [],
            contentCategoriesLevel1Loading: true,
            contentCategoriesLevel1: [],
            contentCategoriesLevel2: [],
            contentCategoriesLevel3: [],
            hasContentCategoryLevel1: false,
            hasContentCategoryLevel2: false,
            hasSport: false,
            hasTournamentCategory: false,
            hasSelectedContentCategoryLevel1: false,
            hasSelectedContentCategoryLevel2: false,
            hasSelectedSport: false,
            hasSelectedTournamentCategory: false,
            selectedContentCategoriesLevel2: [],
            selectedContentCategoriesLevel3: [],
            selectedTournamentCategories: [],
            selectedTournaments: [],
            tournamentCategories: [],
            tournaments: [],
            selectedSports: [],
            sports: [],
            sportsLoading: true,
        };

        componentDidMount() {
            this.setState((prevState) =>
                updateFilterState(prevState, getMaxMinDate(this.props.filters, this.props.restoreFilterValues), this.setStateHandler)
            );
        }

        onEventTypeChange = (event, value) => {
            handleOnEventTypeChange(event, value, this.setStateHandler);
            fetchSports(value, this.props.form, this.setStateHandler);
            fetchContentCategoriesLevel1(value, this.props.form, this.setStateHandler);
        };

        onSportChange = (event, value) => {
            handleOnSportChange(event, value, this.setStateHandler, () => {
                fetchTournamentCategories(
                    value,
                    this.props.form,
                    this.state.selectedTournamentCategories,
                    this.setStateHandler
                );
            }
            );
        };

        onTournamentCategoryChange = (event, value) => {
            handleOnTournamentCategoryChange(event, value, this.setStateHandler, () => {
                fetchTournaments(
                    value,
                    this.props.form,
                    this.state.selectedTournaments,
                    this.setStateHandler
                );
            }
            );
        };

        onTournamentChange = (event, value) => {
            this.setState(() => ({
                selectedTournaments: value,
            }));
        };

        onContentCategoryLevel1Change = (event, value) => {
            handleOnContentCategoryLevel1Change(event, value, this.setStateHandler, () => {
                fetchContentCategoryLevel2(
                    value,
                    this.props.form,
                    this.state.selectedContentCategoriesLevel2,
                    this.setStateHandler
                );
            }
            );
        };

        onContentCategoryLevel2Change = (event, value) => {
            handleOnContentCategoryLevel2Change(event, value, this.setStateHandler, () => {
                fetchContentCategoryLevel3(
                    value,
                    this.props.form,
                    this.state.selectedContentCategoriesLevel3,
                    this.setStateHandler
                );
            }
            );
        };

        onContentCategoryLevel3Change = (event, value) => {
            this.setState(() => ({
                selectedContentCategoriesLevel3: value,
            }));
        };

        onChange = (date, name) => {
            this.setState((prevState) => updateDateState(prevState, date, name));
        };

        setStateHandler = (stateObject, callbackFunc = () => {}) => {
            this.setState(() => (stateObject), callbackFunc);
        };

        render() {
            const filtersProps = {
                ...this.props,
                state: this.state,
                clientFilters: this.props.filters[filterName + this.props.additionalFilterId],
                dateConfigProps: {
                    onChange: this.onChange,
                    fromMaxDate: this.state.fromMaxDate,
                    fromMaxDateDefault: this.state.fromMaxDateDefault,
                    fromMaxTime: this.state.fromMaxTime,
                    toMinDate: this.state.toMinDate,
                    toMinDateDefault: this.state.toMinDateDefault,
                    toMinTime: this.state.toMinTime,
                    restoreFilterValues: this.props.restoreFilterValues,
                },
                methods: {
                    onEventTypeChange: this.onEventTypeChange,
                    onSportChange: this.onSportChange,
                    onTournamentCategoryChange: this.onTournamentCategoryChange,
                    onTournamentChange: this.onTournamentChange,
                    onContentCategoryLevel1Change: this.onContentCategoryLevel1Change,
                    onContentCategoryLevel2Change: this.onContentCategoryLevel2Change,
                    onContentCategoryLevel3Change: this.onContentCategoryLevel3Change,
                },
            };

            return (<WrappedComponent {...filtersProps}/>);
        }
    }

    ClientBookingsFiltersHOC.displayName = 'ClientBookingsFiltersHOC';

    return FiltersWrapper(withApollo(ClientBookingsFiltersHOC), {
        name: filterName,
        onSubmit: onSubmit,
        initialValues: {
            from: getTodayDate(),
            to: getTomorrowDate(),
            isCancelled: 0,
        },
    });
}
