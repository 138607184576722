import {
    CONTENT_ACTIVATION_TICKET_NUMBER_MAX_LENGTH,
    MINIMUM_SPEND_COMMITMENT_MAX,
    MINIMUM_SPEND_COMMITMENT_MAX_LENGTH,
    MINIMUM_SPEND_COMMITMENT_MIN,
} from '@constants/variables';

import validators from '../../../utils/validators';


const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));
    Object.assign(errors, validators.required(values.client_product, 'client_product'));
    Object.assign(errors, validators.required(values.client_package_type, 'client_package_type'));
    Object.assign(errors, validators.required(values.event_booking_type, 'event_booking_type'));
    Object.assign(errors, validators.required(values.booking_type, 'booking_type'));
    Object.assign(errors, validators.required(values.content_package_selection_type, 'content_package_selection_type'));
    Object.assign(errors, validators.required(values.event_content_variants, 'event_content_variants'));
    Object.assign(errors, validators.maxLength({
        value: values.content_activation_ticket_number,
        maxLength: CONTENT_ACTIVATION_TICKET_NUMBER_MAX_LENGTH,
    }, 'content_activation_ticket_number'));

    if (values.minimum_spend_commitment) {
        Object.assign(errors, validators.floatAndNumbersWithFixedDecimal({
            value: values.minimum_spend_commitment,
        }, 'minimum_spend_commitment'));
        Object.assign(errors, validators.rangeFloat({
            value: values.minimum_spend_commitment,
            min: MINIMUM_SPEND_COMMITMENT_MIN,
            max: MINIMUM_SPEND_COMMITMENT_MAX,
        }, 'minimum_spend_commitment'));
        Object.assign(errors, validators.maxLength({
            value: values.minimum_spend_commitment,
            maxLength: MINIMUM_SPEND_COMMITMENT_MAX_LENGTH,
        }, 'minimum_spend_commitment'));
    }

    if (new Date(values.start_date) > new Date(values.end_date)) {
        Object.assign(errors, {end_date: 'End date must be later than the Start date'});
    }
    Object.assign(errors, validators.date(values.start_date, 'start_date'));
    Object.assign(errors, validators.date(values.end_date, 'end_date'));
    Object.assign(errors, validators.required(values.start_date, 'start_date'));
    Object.assign(errors, validators.required(values.end_date, 'end_date'));

    if (!values.cdn_price_active) {
        Object.assign(errors, validators.floatAndNumbersWithFixedDecimal({
            value: values.cdn_price,
        }, 'cdn_price'));
    }

    if (!values.has_additional_cdn_bucket_active && values.has_additional_cdn_bucket) {
        Object.assign(errors, validators.required(values.additional_cdn_bucket_size_gb, 'additional_cdn_bucket_size_gb'));
        Object.assign(errors, validators.digitsOnly(values.additional_cdn_bucket_size_gb, 'additional_cdn_bucket_size_gb'));
    }

    return errors;
};

export default validate;
