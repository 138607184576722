import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {isEmpty as _isEmpty} from 'lodash';

import HeaderRenderer from '@appComponents/HeaderRenderer';
import ClientVideoPlayerIndexButtons from '@modules/client/components/ClientVideoPlayerIndexButtons';
import ClientVideoPlayerIndexTable from '@modules/client/components/ClientVideoPlayerIndexTable';
import useQuery from '@utils/hooks/useQuery';
import {GetClientVplLicenceKeys} from '@graphql/clientVplLicenceKey/query';
import {showErrorModal} from '@utils/modal';
import {getLink} from '@appComponents/Link';

const ClientVideoPlayerIndex = ({clientId}) => {
    const {data = {}, loading, error} = useQuery({
        query: GetClientVplLicenceKeys,
        options: {
            variables: {
                client: clientId,
                notifyOnNetworkStatusChange: true,
            },
        },
    });

    useEffect(() => {
        if (!_isEmpty(error)) {
            showErrorModal({
                header: 'Error',
                redirectLink: getLink('clients.clientVideoPlayer.index', {id: clientId}),
                text: 'There was an error while fetching the data.',
                errorButtonIcon: 'arrow circle left',
                errorButtonText: 'Back to the list',
            });
        }
    }, [error]);

    const {clientVplLicenceKeys = []} = data;

    return (
        <>
            <HeaderRenderer
                buttons={ClientVideoPlayerIndexButtons}
                buttonsProps={{clientId}}
            />
            <ClientVideoPlayerIndexTable
                clientId={clientId}
                loading={loading}
                data={clientVplLicenceKeys}
            />
        </>
    );
};

ClientVideoPlayerIndex.propTypes = {
    clientId: PropTypes.number.isRequired,
};
export default ClientVideoPlayerIndex;
