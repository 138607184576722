import {
    camelCase as _camelCase,
    has as _has,
    isUndefined as _isUndefined,
    omit as _omit,
} from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {gql, withApollo} from 'react-apollo';
import {isAuthorized} from '@appComponents/Authorization';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import MessageBox from '@appComponents/MessageBox';
import {renderModalError} from '@utils/forms';
import {getLink} from '@appComponents/Link';
import Tabs from '@appComponents/Tabs';
import mapModulesToProps from '@utils/mapModulesToProps';
import {NOT_AUTHORIZED} from '@constants/messages';
import {CLIENT_PRODUCT} from '@constants/resources';
import {routes} from '@constants/routes';
import {
    EXPORT_EXCEL_URL_FRAGMENT,
    PRODUCT_LCI,
    PRODUCT_LCO,
    PRODUCT_LCPP,
    PRODUCT_LCR,
    PRODUCT_LCT,
    PRODUCT_SPOTT,
    PRODUCT_THIRD_PARTY,
    PRODUCT_QB,
    SECURITY_PRIVILEGES_CREATE,
    SECURITY_PRIVILEGES_UPDATE,
} from '@constants/variables';
import * as clientGraphQl from '@graphql/clients/index';
import ClientEventBookings from '@modules/client/views/ClientEventBookings';
import {showModal} from '@utils/modal';
import {convertToInt, isUrlParamValid} from '@utils/helpers';
import WhitelistedIpTabsLayout from '@modules/client/layouts/WhitelistedIpTabsLayout';
import ClientVideoPlayerIndex from '@modules/client/views/ClientVideoPlayerIndex';

import ClientTabsButtons from '../components/ClientTabsButtons';
import ClientFormAdd from '../forms/ClientFormAdd';
import ClientFormEdit from '../forms/ClientFormEdit';
import ClientProductLciForm from '../forms/ClientProductLciForm';
import ClientProductLciModel from '../forms/ClientProductLciModel';
import ClientProductLcoForm from '../forms/ClientProductLcoForm';
import ClientProductLcoModel from '../forms/ClientProductLcoModel';
import ClientProductLcppForm from '../forms/ClientProductLcppForm';
import ClientProductLcppModel from '../forms/ClientProductLcppModel';
import ClientProductLcrModel from '../forms/ClientProductLcrModel';
import ClientProductLctForm from '../forms/ClientProductLctForm';
import ClientProductLctModel from '../forms/ClientProductLctModel';
import ClientProductLcrForm from '../forms/ClientProductLcrForm';
import ClientProductSpottForm from '../forms/ClientProductSpottForm';
import ClientProductSpottModel from '../forms/ClientProductSpottModel';
import ClientProductQbForm from '../forms/ClientProductQbForm';
import ClientProductQbModel from '../forms/ClientProductQbModel';
import ClientAnalyticsSetupTabLayout from '../layouts/ClientAnalyticsSetupTabsLayout';
import ClientProductThirdPartyLayout from '../layouts/ClientProductThirdPartyLayout';
import ApiKeyIndex from '../views/ApiKeyIndex';
import CdnConfigurationsTab from '../views/CdnConfigurationsTab';
import ClientBookings from '../views/ClientBookings';
import ClientDrmSetupIndex from '../views/ClientDrmSetupIndex';
import ClientMasterBookings from '../views/ClientMasterBookings';
import ClientOfferings from '../views/ClientOfferings';
import ContactPersonsIndex from '../views/ContactPersonsIndex';
import PackagesTab from '../views/PackagesTab';
import ProductAdd from '../views/ProductAdd';
import ProductTab from '../views/ProductTab';

class ClientTabsLayout extends React.Component {
    static propTypes = {
        client: PropTypes.object,
        clientData: PropTypes.object,
        clientProductLci: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLcr: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLco: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLcpp: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductLct: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductSpott: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductQb: PropTypes.shape({
            id: PropTypes.number,
        }),
        clientProductThirdParty: PropTypes.object,
        DataClientForStore: PropTypes.object,
        Entity: PropTypes.object,
        hasLciProduct: PropTypes.bool,
        hasLcrProduct: PropTypes.bool,
        hasLcoProduct: PropTypes.bool,
        hasLcppProduct: PropTypes.bool,
        hasLctProduct: PropTypes.bool,
        hasSpottProduct: PropTypes.bool,
        hasThirdPartyProduct: PropTypes.bool,
        hasQuickBetsProduct: PropTypes.bool,
        history: PropTypes.object,
        lciProductIsDisabled: PropTypes.bool,
        lciProductIsTrial: PropTypes.bool,
        lcrProductIsDisabled: PropTypes.bool,
        lcrProductIsTrial: PropTypes.bool,
        lcoProductIsDisabled: PropTypes.bool,
        lcoProductIsTrial: PropTypes.bool,
        lcppProductIsDisabled: PropTypes.bool,
        lcppProductIsTrial: PropTypes.bool,
        lctProductIsDisabled: PropTypes.bool,
        lctProductIsTrial: PropTypes.bool,
        spottProductIsDisabled: PropTypes.bool,
        spottProductIsTrial: PropTypes.bool,
        thirdPartyProductIsDisabled: PropTypes.bool,
        thirdPartyProductIsTrial: PropTypes.bool,
        qbProductIsDisabled: PropTypes.bool,
        qbProductIsTrial: PropTypes.bool,
        match: PropTypes.object.isRequired,
        modal: PropTypes.object,
        Modal: PropTypes.object,
        params: PropTypes.object,
        userPrivileges: PropTypes.object,
        has_event_booking_support: PropTypes.bool,
    };

    static defaultProps = {
        clientProductLci: {
            id: null,
        },
        clientProductLcr: {
            id: null,
        },
        clientProductLco: {
            id: null,
        },
        clientProductLcpp: {
            id: null,
        },
        clientProductLct: {
            id: null,
        },
        clientProductSpott: {
            id: null,
        },
        clientProductQb: {
            id: null,
        },
        hasLciProduct: false,
        hasLcrProduct: false,
        hasLcoProduct: false,
        hasLcppProduct: false,
        hasLctProduct: false,
        hasSpottProduct: false,
        hasThirdPartyProduct: false,
        hasQuickBetsProduct: false,
    };

    constructor() {
        super();

        this.state = {
            loading: false,
            renderTabs: false,
        };
    }

    componentWillMount() {
        this.props = Object.assign({}, this.props, this.checkProductAvailability());

        this.setState(() => this.getClientTabsState());

        this.getClientFromGraphQL();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.modal.isVisible) {
            return
        }

        this.props = Object.assign({}, nextProps, this.checkProductAvailability(nextProps));

        const tabStates = this.getClientTabsState();

        if (
            !this.checkRouteIsAllowed(tabStates)
            && this.checkRouteIsAllowed(tabStates) !== null)
        {
            const tabStatesContent = (
                tabStates.active === routes.clients.products.thirdParty.edit.path ||
                tabStates.active === routes.clients.products.lci.edit.path ||
                tabStates.active === routes.clients.products.lco.edit.path ||
                tabStates.active === routes.clients.products.lcpp.edit.path ||
                tabStates.active === routes.clients.products.lcr.edit.path ||
                tabStates.active === routes.clients.products.lct.edit.path ||
                tabStates.active === routes.clients.products.spott.edit.path ||
                tabStates.active === routes.clients.products.qb.edit.path
            )
                ? "Product not found!"
                : "Can't add another product!";

            const returnLink = getLink('clients.edit', {id: this.props.clientData.id});
            this.props.Modal.setModal({
                content: renderModalError('Product', returnLink, tabStatesContent),
                isVisible: true,
            });
        }

        this.setState(() => tabStates);

        if (!this.state.clientDataRequestSent && _isUndefined(this.props.clientData)) {
            this.getClientFromGraphQL();
        }
    }

    componentWillUnmount() {
        this.props.Entity.removeEntity("client");
    }

    checkProductAvailability = (props) => {
        if (_isUndefined(props)) {
            props = this.props;
        }

        let name = "",
            hasLciProduct = false,
            hasLcrProduct = false,
            hasLcoProduct = false,
            hasLcppProduct = false,
            hasLctProduct = false,
            hasSpottProduct = false,
            hasThirdPartyProduct = false,
            hasQuickBetsProduct = false,
            isDisabledProductLci = false,
            isDisabledProductLco = false,
            isDisabledProductLcpp = false,
            isDisabledProductLcr = false,
            isDisabledProductLct = false,
            isDisabledProductSpott = false,
            isDisabledProductThirdParty = false,
            isDisabledProductQb = false,
            isTrialProductLci = false,
            isTrialProductLco = false,
            isTrialProductLcpp = false,
            isTrialProductLcr = false,
            isTrialProductLct = false,
            isTrialProductSpott = false,
            isTrialProductThirdParty = false,
            isTrialProductQb = false,
            clientProductLci = null,
            clientProductLcr = null,
            clientProductLco = null,
            clientProductLcpp = null,
            clientProductLct = null,
            clientProductSpott = null,
            clientProductThirdParty = null,
            clientProductQb = null;

        if (props.clientData) {
            name = props.clientData.name;

            clientProductLci = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_LCI === convertToInt(clientProduct.product.id)
            );

            clientProductLcr = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_LCR === convertToInt(clientProduct.product.id)
            );

            clientProductLco = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_LCO === convertToInt(clientProduct.product.id)
            );

            clientProductLcpp = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_LCPP === convertToInt(clientProduct.product.id)
            );

            clientProductLct = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_LCT === convertToInt(clientProduct.product.id)
            );

            clientProductSpott = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_SPOTT === convertToInt(clientProduct.product.id)
            );

            clientProductThirdParty = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_THIRD_PARTY === convertToInt(clientProduct.product.id)
            );

            clientProductQb = props.clientData.clientProducts.find((clientProduct) =>
                PRODUCT_QB === convertToInt(clientProduct.product.id)
            );

            hasLciProduct = !_isUndefined(clientProductLci);
            hasLcrProduct = !_isUndefined(clientProductLcr);
            hasLcoProduct = !_isUndefined(clientProductLco);
            hasLcppProduct = !_isUndefined(clientProductLcpp);
            hasLctProduct = !_isUndefined(clientProductLct);
            hasSpottProduct = !_isUndefined(clientProductSpott);
            hasThirdPartyProduct = !_isUndefined(clientProductThirdParty);
            hasQuickBetsProduct = !_isUndefined(clientProductQb);

            if (hasLciProduct) {
                isDisabledProductLci = clientProductLci.is_disabled;
                isTrialProductLci = clientProductLci.is_trial;
            }

            if (hasLcoProduct) {
                isDisabledProductLco = clientProductLco.is_disabled;
                isTrialProductLco = clientProductLco.is_trial;
            }

            if (hasLcppProduct) {
                isDisabledProductLcpp = clientProductLcpp.is_disabled;
                isTrialProductLcpp = clientProductLcpp.is_trial;
            }

            if (hasLcrProduct) {
                isDisabledProductLcr = clientProductLcr.is_disabled;
                isTrialProductLcr = clientProductLcr.is_trial;
            }

            if (hasLctProduct) {
                isDisabledProductLct = clientProductLct.is_disabled;
                isTrialProductLct = clientProductLct.is_trial;
            }

            if (hasSpottProduct) {
                isDisabledProductSpott = clientProductSpott.is_disabled;
                isTrialProductSpott = clientProductSpott.is_trial;
            }

            if (hasThirdPartyProduct) {
                isDisabledProductThirdParty = clientProductThirdParty.is_disabled;
                isTrialProductThirdParty = clientProductThirdParty.is_trial;
            }

            if (hasQuickBetsProduct) {
                isDisabledProductQb = clientProductQb.is_disabled;
                isTrialProductQb = clientProductQb.is_trial;
            }
        }

        return {
            clientName: name,
            clientProductLci: clientProductLci,
            clientProductLco: clientProductLco,
            clientProductLcpp: clientProductLcpp,
            clientProductLcr: clientProductLcr,
            clientProductLct: clientProductLct,
            clientProductSpott: clientProductSpott,
            clientProductThirdParty: clientProductThirdParty,
            clientProductQb: clientProductQb,
            hasLciProduct: hasLciProduct,
            hasLcrProduct: hasLcrProduct,
            hasLcoProduct: hasLcoProduct,
            hasLcppProduct: hasLcppProduct,
            hasLctProduct: hasLctProduct,
            hasSpottProduct: hasSpottProduct,
            hasThirdPartyProduct: hasThirdPartyProduct,
            hasQuickBetsProduct: hasQuickBetsProduct,
            lciProductIsDisabled: isDisabledProductLci,
            lciProductIsTrial: isTrialProductLci,
            lcoProductIsDisabled: isDisabledProductLco,
            lcoProductIsTrial: isTrialProductLco,
            lcppProductIsDisabled: isDisabledProductLcpp,
            lcppProductIsTrial: isTrialProductLcpp,
            lcrProductIsDisabled: isDisabledProductLcr,
            lcrProductIsTrial: isTrialProductLcr,
            lctProductIsDisabled: isDisabledProductLct,
            lctProductIsTrial: isTrialProductLct,
            spottProductIsDisabled: isDisabledProductSpott,
            spottProductIsTrial: isTrialProductSpott,
            thirdPartyProductIsDisabled: isDisabledProductThirdParty,
            thirdPartyProductIsTrial: isTrialProductThirdParty,
            qbProductIsDisabled: isDisabledProductQb,
            qbProductIsTrial: isTrialProductQb,
        };
    };

    getClientFromGraphQL = () => {
        if (_isUndefined(this.props.match.params.id)) {
            return;
        }

        this.setState(() => ({
            loading: true,
            clientDataRequestSent: true
        }));

        if (!isUrlParamValid(this.props.match.params.id)) {
            this.setState(() => ({loading: false}));
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError('Client', routes.clients.index.path),
            });
        }

        this.props.client.query({
            fetchPolicy: "network-only",
            query: ClientForStore,
            variables: {
                id: this.props.match.params.id,
            },
        }).then((data) => {
            if (data) {
                this.props.Entity.setEntity(prepareClientForStore(data.data.client));

                if(!this.hasRequestedProduct(this.props)) {

                    const returnLink = getLink('clients.edit', {id: this.props.clientData.id });

                    this.props.Modal.setModal({
                        isVisible: true,
                        content: renderModalError('Product', returnLink, 'Client product was not found'),
                    });
                }
            }

            this.setState(() => ({
                loading: false,
                renderTabs: true
            }));
        }).catch(() => {
            this.setState(() => ({loading: false}));
            this.props.Modal.setModal({
                isVisible: true,
                content: renderModalError('Client', routes.clients.index.path),
            });
        });
    };

    checkRouteIsAllowed = (activeRoute) => {
        if (_isUndefined(this.props.clientData)) {
            return true;
        }

        switch (activeRoute.active) {
            case routes.clients.products.lci.add.path:
                return (!activeRoute.tabs[routes.clients.products.lci.edit.path]);
            case routes.clients.products.lci.edit.path:
                return (activeRoute.tabs[routes.clients.products.lci.edit.path]);
            case routes.clients.products.lcr.add.path:
                return (!activeRoute.tabs[routes.clients.products.lcr.edit.path]);
            case routes.clients.products.lcr.edit.path:
                return (activeRoute.tabs[routes.clients.products.lcr.edit.path]);
            case routes.clients.products.lco.add.path:
                return (!activeRoute.tabs[routes.clients.products.lco.edit.path]);
            case routes.clients.products.lco.edit.path:
                return (activeRoute.tabs[routes.clients.products.lco.edit.path]);
            case routes.clients.products.lcpp.add.path:
                return (!activeRoute.tabs[routes.clients.products.lcpp.edit.path]);
            case routes.clients.products.lcpp.edit.path:
                return (activeRoute.tabs[routes.clients.products.lcpp.edit.path]);
            case routes.clients.products.lct.add.path:
                return (!activeRoute.tabs[routes.clients.products.lct.edit.path]);
            case routes.clients.products.lct.edit.path:
                return (activeRoute.tabs[routes.clients.products.lct.edit.path]);
            case routes.clients.products.spott.add.path:
                return (!activeRoute.tabs[routes.clients.products.spott.edit.path]);
            case routes.clients.products.spott.edit.path:
                return (activeRoute.tabs[routes.clients.products.spott.edit.path]);
            case routes.clients.products.thirdParty.add.path:
                return (!activeRoute.tabs[routes.clients.products.thirdParty.edit.path]);
            case routes.clients.products.thirdParty.edit.path:
                return (activeRoute.tabs[routes.clients.products.thirdParty.edit.path]);
            case routes.clients.products.qb.add.path:
                return (!activeRoute.tabs[routes.clients.products.qb.edit.path]);
            case routes.clients.products.qb.edit.path:
                return (activeRoute.tabs[routes.clients.products.qb.edit.path]);
            default:
                return true;
        }
    };

    hasRequestedProduct = () => {
        const active = this.props.match.path,
            clientProducts = this.props.clientData.clientProducts,
            productsLciRoutes = routes.clients.products.lci,
            productsLcrRoutes = routes.clients.products.lcr,
            productsLcoRoutes = routes.clients.products.lco,
            productsLcppRoutes = routes.clients.products.lcpp,
            productsLctRoutes = routes.clients.products.lct,
            productsSpottRoutes = routes.clients.products.spott,
            productsThirdPartyRoutes = routes.clients.products.thirdParty,
            productsQbRoutes = routes.clients.products.qb;
        let requestedProduct = null;

        if (active === productsLciRoutes.edit.path) {
            requestedProduct = PRODUCT_LCI;
        } else if (active === productsLcrRoutes.edit.path) {
            requestedProduct = PRODUCT_LCR;
        } else if (active === productsLcoRoutes.edit.path) {
            requestedProduct = PRODUCT_LCO;
        } else if (active === productsLcppRoutes.edit.path) {
            requestedProduct = PRODUCT_LCPP;
        } else if (active === productsLctRoutes.edit.path) {
            requestedProduct = PRODUCT_LCT;
        } else if (active === productsSpottRoutes.edit.path) {
            requestedProduct = PRODUCT_SPOTT;
        } else if (active === productsThirdPartyRoutes.edit.path
            || active === productsThirdPartyRoutes.cdns.index.path
            || active === `${productsThirdPartyRoutes.cdns.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.cdns.add.path
            || active === productsThirdPartyRoutes.cdns.edit.path
            || active === productsThirdPartyRoutes.pullOrigins.index.path
            || active === `${productsThirdPartyRoutes.pullOrigins.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.pullOrigins.add.path
            || active === productsThirdPartyRoutes.pullOrigins.edit.path
            || active === productsThirdPartyRoutes.streamNamesPush.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPush.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPush.add.path
            || active === productsThirdPartyRoutes.streamNamesPush.edit.path
            || active === productsThirdPartyRoutes.streamNamesPull.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPull.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPull.add.path
            || active === productsThirdPartyRoutes.streamNamesPull.edit.path
            || active === productsThirdPartyRoutes.vodStorages.index.path
            || active === `${productsThirdPartyRoutes.vodStorages.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.vodStorages.add.path
            || active === productsThirdPartyRoutes.vodStorages.edit.path
        ) {
            requestedProduct = PRODUCT_THIRD_PARTY;
        } else if (active === productsQbRoutes.edit.path) {
            requestedProduct = PRODUCT_QB;
        } else {
            return true;
        }

        return !_isUndefined(clientProducts.find((clientProduct) =>
            convertToInt(clientProduct.product.id) === requestedProduct
        ));
    };

    getClientTabsState = () => {
        const clientId = convertToInt(this.props.match.params.id),
            clientData = this.props.clientData,
            contactPersonsRoutes = routes.clients.contactPersons,
            productsRoutes = routes.clients.products,
            productsThirdPartyRoutes = routes.clients.products.thirdParty,
            packagesRoutes = routes.clients.packages,
            whitelistedIpsRoutes = routes.clients.whitelistedIps,
            clientAnalyticsSetupRoutes = routes.clients.clientAnalyticsSetup,
            clientAnalyticsSetupLabelsRoutes = routes.clients.clientAnalyticsSetup.labels,
            masterBookingsRoutes = routes.clients.masterBookings,
            masterBookingsExcelExportRoute = `${masterBookingsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
            eventBookingsRoutes = routes.clients.eventBookings,
            eventBookingsExcelExportRoute = `${eventBookingsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
            bookingsRoutes = routes.clients.bookings,
            bookingsExcelExportRoute = `${bookingsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
            offeringsRoutes = routes.clients.offerings,
            offeringsExcelExportRoute = `${offeringsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`,
            clientCdnConfigurationRoutes = routes.clients.clientCdnConfiguration,
            clientApiKeyRoutes = routes.clients.apiKey,
            clientVideoPlayer = routes.clients.clientVideoPlayer,
            {userPrivileges} = this.props;

        let active = this.props.match.path,
            shouldOpenModal = false,
            tabs = {};

        if (
            active === clientAnalyticsSetupLabelsRoutes.index.path
            || active === `${clientAnalyticsSetupLabelsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === clientAnalyticsSetupLabelsRoutes.add.path
            || active === clientAnalyticsSetupLabelsRoutes.edit.path
        ) {
            active = clientAnalyticsSetupRoutes.index.path;
        }

        if (active === whitelistedIpsRoutes.geoRestrictions.add.path
            || active === whitelistedIpsRoutes.geoRestrictions.edit.path
            || active === whitelistedIpsRoutes.geoRestrictions.index.path
            || active === whitelistedIpsRoutes.add.path
            || active === whitelistedIpsRoutes.edit.path
            || active === `${whitelistedIpsRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = whitelistedIpsRoutes.index.path;
        }

        if (active === contactPersonsRoutes.add.path || active === contactPersonsRoutes.edit.path) {
            active = contactPersonsRoutes.index.path;
        }

        if (active === masterBookingsRoutes.edit.path
            || active === masterBookingsRoutes.editGeoRestrictions.path
            || active === masterBookingsExcelExportRoute) {
            active = masterBookingsRoutes.index.path;
        }

        if (active === eventBookingsRoutes.blackoutZones.path
            || active === eventBookingsRoutes.editGeoRestrictions.path
            || active === eventBookingsRoutes.clips.path
            || active === eventBookingsRoutes.edit.path
            || active === eventBookingsExcelExportRoute) {
            active = eventBookingsRoutes.index.path;
        }

        if (active === bookingsRoutes.edit.path
            || active === bookingsRoutes.editGeoRestrictions.path
            || active === bookingsRoutes.bulkChange.path
            || active === bookingsRoutes.bulkCancel.path
            || active === bookingsRoutes.blackoutZones.path
            || active === bookingsExcelExportRoute) {
            active = bookingsRoutes.index.path;
        }

        if (active === offeringsExcelExportRoute) {
            active = offeringsRoutes.index.path;
        }

        if (active === `${packagesRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === packagesRoutes.add.path
            || active === packagesRoutes.edit.path
            || active === packagesRoutes.duplicate.path
            || active === packagesRoutes.geoRestrictions.index.path
            || active === packagesRoutes.geoRestrictions.add.path
            || active === packagesRoutes.geoRestrictions.edit.path
            || active === packagesRoutes.geoRestrictions.editGeoRestrictions.path
            || active === packagesRoutes.content.index.path
            || active === packagesRoutes.content.geoRestrictions.path
        ) {
            active = packagesRoutes.index.path;
        }

        if (active === packagesRoutes.duplicate.path) {
            shouldOpenModal = true;
        }

        if (active === productsThirdPartyRoutes.cdns.index.path
            || active === `${productsThirdPartyRoutes.cdns.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.cdns.add.path
            || active === productsThirdPartyRoutes.cdns.edit.path
            || active === productsThirdPartyRoutes.pullOrigins.index.path
            || active === `${productsThirdPartyRoutes.pullOrigins.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.pullOrigins.add.path
            || active === productsThirdPartyRoutes.pullOrigins.edit.path
            || active === productsThirdPartyRoutes.streamNamesPush.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPush.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPush.add.path
            || active === productsThirdPartyRoutes.streamNamesPush.edit.path
            || active === productsThirdPartyRoutes.streamNamesPull.index.path
            || active === `${productsThirdPartyRoutes.streamNamesPull.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.streamNamesPull.add.path
            || active === productsThirdPartyRoutes.streamNamesPull.edit.path
            || active === productsThirdPartyRoutes.vodStorages.index.path
            || active === `${productsThirdPartyRoutes.vodStorages.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === productsThirdPartyRoutes.vodStorages.add.path
            || active === productsThirdPartyRoutes.vodStorages.edit.path
            || active === productsThirdPartyRoutes.geolocation.index.path
            || active === productsThirdPartyRoutes.geolocation.add.path
            || active === productsThirdPartyRoutes.geolocation.edit.path
        ) {
            if (active === productsThirdPartyRoutes.vodStorages.add.path
                || active === productsThirdPartyRoutes.vodStorages.edit.path
                || active === productsThirdPartyRoutes.geolocation.add.path
                || active === productsThirdPartyRoutes.geolocation.edit.path
            ) {
                shouldOpenModal = true;
            }

            active = routes.clients.products.thirdParty.edit.path;
        }

        if (active === clientCdnConfigurationRoutes.add.path
            || active === clientCdnConfigurationRoutes.edit.path
            || active === `${clientCdnConfigurationRoutes.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
            || active === clientCdnConfigurationRoutes.akamaiLiveStreamName.index.path
            || active === clientCdnConfigurationRoutes.akamaiLiveStreamName.add.path
            || active === clientCdnConfigurationRoutes.akamaiLiveStreamName.edit.path
            || active === `${clientCdnConfigurationRoutes.akamaiLiveStreamName.index.path}${EXPORT_EXCEL_URL_FRAGMENT}`
        ) {
            active = clientCdnConfigurationRoutes.index.path;
        }

        if (active === clientApiKeyRoutes.add.path || active === clientApiKeyRoutes.edit.path) {
            active = clientApiKeyRoutes.index.path;
        }

        if (active === clientVideoPlayer.add.path
            || active === clientVideoPlayer.edit.path
            || active === clientVideoPlayer.index.path) {
            active = clientVideoPlayer.index.path;
        }

        const activeKeys = active.split('/');

        if ('products' === activeKeys[3]
            && activeKeys[4]
            && (
                'geo-restrictions' === activeKeys[5]
                || 'geolocation' === activeKeys[5]
                || 'notification-history' === activeKeys[5]
            )
        ) {
            const productKey = ('lcp-p' === activeKeys[4])
                ? _camelCase(activeKeys[4].replace('-', ''))
                : _camelCase(activeKeys[4]);

            if (_has(productsRoutes, productKey)) {
                active = productsRoutes[productKey].edit.path;
            }
        }

        if (this.props.match.path === routes.clients.add.path) {
            tabs = {
                [routes.clients.add.path]: {
                    key: routes.clients.add.path,
                    render: <ClientFormAdd/>,
                    text: "General information",
                    url: "clients.add",
                },
            };
        } else {
            tabs = {
                [routes.clients.edit.path]: {
                    key: routes.clients.edit.path,
                    render: <ClientFormEdit/>,
                    text: "General information",
                    url: "clients.edit",
                    urlParams: {id: this.props.match.params.id},
                },
                [contactPersonsRoutes.index.path]: {
                    key: contactPersonsRoutes.index.path,
                    render: <ContactPersonsIndex clientId={clientId}/>,
                    text: "Contact persons",
                    url: "clients.contactPersons.index",
                    urlParams: {id: this.props.match.params.id},
                },
            };

            if (this.props.hasLcrProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.edit.path]: {
                            isDisabled: this.props.lcrProductIsDisabled,
                            key: productsRoutes.lcr.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.props.clientProductLcr.id)}/>,
                            text: `LCR ${this.props.lcrProductIsTrial ? '(trial)' : ''}`,
                            url: "clients.products.lcr.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.edit.path]: {
                            isDisabled: this.props.lcrProductIsDisabled,
                            key: productsRoutes.lcr.edit.path,
                            text: "LCR",
                            render: NOT_AUTHORIZED,
                            url: "clients.products.lcr.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.lcr.add.path && this.props.hasLcrProduct === false) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcr.add.path,
                            render: <ProductAdd
                                form={ClientProductLcrForm}
                                model={ClientProductLcrModel}
                                productId={PRODUCT_LCR}
                            />,
                            text: "LCR",
                            url: "clients.products.lcr.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcr.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcr.add.path,
                            render: NOT_AUTHORIZED,
                            text: "LCR",
                            url: "clients.products.lcr.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.props.hasLcoProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.edit.path]: {
                            isDisabled: this.props.lcoProductIsDisabled,
                            key: productsRoutes.lco.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.props.clientProductLco.id)}/>,
                            url: "clients.products.lco.edit",
                            urlParams: {id: this.props.match.params.id},
                            text: `LCO ${this.props.lcoProductIsTrial ? '(trial)' : ''}`,
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.edit.path]: {
                            isDisabled: this.props.lcoProductIsDisabled,
                            key: productsRoutes.lco.edit.path,
                            render: NOT_AUTHORIZED,
                            text: "LCO",
                            url: "clients.products.lco.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.lco.add.path && this.props.hasLcoProduct === false) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lco.add.path,
                            render: <ProductAdd
                                form={ClientProductLcoForm}
                                model={ClientProductLcoModel}
                                productId={PRODUCT_LCO}
                            />,
                            text: "LCO",
                            url: "clients.products.lco.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lco.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lco.add.path,
                            render: NOT_AUTHORIZED,
                            text: "LCO",
                            url: "clients.products.lco.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.props.hasLcppProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.edit.path]: {
                            isDisabled: this.props.lcppProductIsDisabled,
                            key: productsRoutes.lcpp.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.props.clientProductLcpp.id)}/>,
                            url: "clients.products.lcpp.edit",
                            urlParams: {id: this.props.match.params.id},
                            text: `LCP-P ${this.props.lcppProductIsTrial ? '(trial)' : ''}`,
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.edit.path]: {
                            isDisabled: this.props.lcppProductIsDisabled,
                            key: productsRoutes.lcpp.edit.path,
                            render: NOT_AUTHORIZED,
                            text: "LCP-P",
                            url: "clients.products.lcpp.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (routes.clients.products.lcpp.add.path === active && false === this.props.hasLcppProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcpp.add.path,
                            render: <ProductAdd
                                form={ClientProductLcppForm}
                                model={ClientProductLcppModel}
                                productId={PRODUCT_LCPP}
                            />,
                            text: "LCP-P",
                            url: "clients.products.lcpp.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lcpp.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lcpp.add.path,
                            render: NOT_AUTHORIZED,
                            text: "LCP-P",
                            url: "clients.products.lcpp.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.props.hasLctProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.edit.path]: {
                            isDisabled: this.props.lctProductIsDisabled,
                            key: productsRoutes.lct.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.props.clientProductLct.id)}/>,
                            text: `LCT ${this.props.lctProductIsTrial ? '(trial)' : ''}`,
                            url: "clients.products.lct.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.edit.path]: {
                            isDisabled: this.props.lctProductIsDisabled,
                            key: productsRoutes.lct.edit.path,
                            text: "LCT",
                            render: NOT_AUTHORIZED,
                            url: "clients.products.lct.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.lct.add.path && this.props.hasLctProduct === false) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lct.add.path,
                            render: <ProductAdd
                                form={ClientProductLctForm}
                                model={ClientProductLctModel}
                                productId={PRODUCT_LCT}
                            />,
                            text: "LCT",
                            url: "clients.products.lct.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.lct.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.lct.add.path,
                            render: NOT_AUTHORIZED,
                            text: "LCT",
                            url: "clients.products.lct.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.props.hasSpottProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.edit.path]: {
                            isDisabled: this.props.spottProductIsDisabled,
                            key: productsRoutes.spott.edit.path,
                            render: <ProductTab clientProductId={convertToInt(this.props.clientProductSpott.id)}/>,
                            text: `SpOTT ${this.props.spottProductIsTrial ? '(trial)' : ''}`,
                            url: "clients.products.spott.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.edit.path]: {
                            isDisabled: this.props.spottProductIsDisabled,
                            key: productsRoutes.spott.edit.path,
                            render: NOT_AUTHORIZED,
                            text: "SpOTT",
                            url: "clients.products.spott.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.spott.add.path && this.props.hasSpottProduct === false) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.spott.add.path,
                            render: <ProductAdd
                                form={ClientProductSpottForm}
                                model={ClientProductSpottModel}
                                productId={PRODUCT_SPOTT}
                            />,
                            text: "SpOTT",
                            url: "clients.products.spott.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.spott.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.spott.add.path,
                            render: NOT_AUTHORIZED,
                            text: "SpOTT",
                            url: "clients.products.spott.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.props.hasThirdPartyProduct) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.edit.path]: {
                            isDisabled: this.props.thirdPartyProductIsDisabled,
                            key: productsRoutes.thirdParty.edit.path,
                            render: <ClientProductThirdPartyLayout
                                clientProductThirdParty={this.props.clientProductThirdParty}
                                shouldOpenModal={shouldOpenModal}
                            />,
                            text: `Third party ${this.props.thirdPartyProductIsTrial ? '(trial)' : ''}`,
                            url: "clients.products.thirdParty.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.edit.path]: {
                            isDisabled: this.props.thirdPartyProductIsDisabled,
                            key: productsRoutes.thirdParty.edit.path,
                            render: NOT_AUTHORIZED,
                            text: "Third party",
                            url: "clients.products.thirdParty.edit",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (active === routes.clients.products.thirdParty.add.path && this.props.hasThirdPartyProduct === false) {
                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.thirdParty.add.path,
                            render: <ClientProductThirdPartyLayout
                                clientProductThirdParty={{}}
                            />,
                            text: "Third party",
                            url: "clients.products.thirdParty.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                } else {
                    tabs = Object.assign(tabs, {
                        [productsRoutes.thirdParty.add.path]: {
                            isDisabled: false,
                            key: productsRoutes.thirdParty.add.path,
                            render: NOT_AUTHORIZED,
                            text: "Third party",
                            url: "clients.products.thirdParty.add",
                            urlParams: {id: this.props.match.params.id},
                        },
                    });
                }
            }

            if (this.props.hasLciProduct) {
                let lciEditTab = {
                    isDisabled: this.props.lciProductIsDisabled,
                    key: productsRoutes.lci.edit.path,
                    url: "clients.products.lci.edit",
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    lciEditTab = Object.assign(lciEditTab, {
                        render: <ProductTab clientProductId={convertToInt(this.props.clientProductLci.id)}/>,
                        text: `LCI ${this.props.lciProductIsTrial ? '(trial)' : ''}`,
                    });
                } else {
                    lciEditTab = Object.assign(lciEditTab, {
                        text: "LCI",
                        render: NOT_AUTHORIZED,
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.lci.edit.path]: lciEditTab,
                });
            }

            if (active === routes.clients.products.lci.add.path && this.props.hasLciProduct === false) {
                let lciAddTab = {
                    isDisabled: false,
                    key: productsRoutes.lci.add.path,
                    url: "clients.products.lci.add",
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    lciAddTab = Object.assign(lciAddTab, {
                        render: <ProductAdd
                            form={ClientProductLciForm}
                            model={ClientProductLciModel}
                            productId={PRODUCT_LCI}
                        />,
                        text: "LCI",
                    });
                } else {
                    lciAddTab = Object.assign(lciAddTab, {
                        render: NOT_AUTHORIZED,
                        text: "LCI",
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.lci.add.path]: lciAddTab,
                });
            }

            if (this.props.hasQuickBetsProduct) {
                let qbEditTab = {
                    isDisabled: this.props.qbProductIsDisabled,
                    key: productsRoutes.qb.edit.path,
                    url: "clients.products.qb.edit",
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_UPDATE, userPrivileges, false)) {
                    qbEditTab = Object.assign(qbEditTab, {
                        render: <ProductTab clientProductId={convertToInt(this.props.clientProductQb.id)}/>,
                        text: `QB ${this.props.qbProductIsTrial ? '(trial)' : ''}`,
                    });
                } else {
                    qbEditTab = Object.assign(qbEditTab, {
                        text: "QB",
                        render: NOT_AUTHORIZED,
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.qb.edit.path]: qbEditTab,
                });
            }

            if (active === routes.clients.products.qb.add.path && false === this.props.hasQuickBetsProduct) {
                let qbAddTab = {
                    isDisabled: false,
                    key: productsRoutes.qb.add.path,
                    url: "clients.products.qb.add",
                    urlParams: {id: this.props.match.params.id},
                };

                if (isAuthorized(CLIENT_PRODUCT, SECURITY_PRIVILEGES_CREATE, userPrivileges, false)) {
                    qbAddTab = Object.assign(qbAddTab, {
                        render: <ProductAdd
                            form={ClientProductQbForm}
                            model={ClientProductQbModel}
                            productId={PRODUCT_QB}
                        />,
                        text: "QB",
                    });
                } else {
                    qbAddTab = Object.assign(qbAddTab, {
                        render: NOT_AUTHORIZED,
                        text: "QB",
                    });
                }

                tabs = Object.assign(tabs, {
                    [productsRoutes.qb.add.path]: qbAddTab,
                });
            }

            tabs = Object.assign(tabs, {
                [packagesRoutes.index.path]: {
                    key: packagesRoutes.index.path,
                    render: <PackagesTab clientId={clientId} />,
                    text: "Packages",
                    url: "clients.packages.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [masterBookingsRoutes.index.path]: {
                    key: masterBookingsRoutes.index.path,
                    render: <ClientMasterBookings/>,
                    text: "Master bookings",
                    url: "clients.masterBookings.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [eventBookingsRoutes.index.path]: {
                    key: eventBookingsRoutes.index.path,
                    render: <ClientEventBookings/>,
                    text: "Event bookings",
                    url: "clients.eventBookings.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [bookingsRoutes.index.path]: {
                    key: bookingsRoutes.index.path,
                    render: <ClientBookings/>,
                    text: "Bookings",
                    url: "clients.bookings.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [offeringsRoutes.index.path]: {
                    key: offeringsRoutes.index.path,
                    render: <ClientOfferings/>,
                    text: "Offerings",
                    url: "clients.offerings.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [clientCdnConfigurationRoutes.index.path]: {
                    key: clientCdnConfigurationRoutes.index.path,
                    render: <CdnConfigurationsTab clientId={clientId}/>,
                    text: "CDN configurations",
                    url: "clients.clientCdnConfiguration.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [routes.clients.drmSetup.index.path]: {
                    key: routes.clients.drmSetup.index.path,
                    render: <ClientDrmSetupIndex
                        clientDrmSetup={this.props.clientData ? this.props.clientData.clientDrmSetup : null}
                        clientId={convertToInt(this.props.match.params.id)}
                    />,
                    text: "DRM setup",
                    url: "clients.drmSetup.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [clientVideoPlayer.index.path]: {
                    key: clientVideoPlayer.index.path,
                    render: <ClientVideoPlayerIndex clientId={convertToInt(this.props.match.params.id)}/>,
                    text: 'Video player',
                    url: 'clients.clientVideoPlayer.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [whitelistedIpsRoutes.index.path]: {
                    key: whitelistedIpsRoutes.index.path,
                    render: <WhitelistedIpTabsLayout />,
                    text: 'IP whitelisting',
                    url: 'clients.whitelistedIps.index',
                    urlParams: {id: this.props.match.params.id},
                },
                [clientAnalyticsSetupRoutes.index.path]: {
                    key: clientAnalyticsSetupRoutes.index.path,
                    render: <ClientAnalyticsSetupTabLayout />,
                    text: "Analytics setup",
                    url: "clients.clientAnalyticsSetup.index",
                    urlParams: {id: this.props.match.params.id},
                },
                [clientApiKeyRoutes.index.path]: {
                    key: clientApiKeyRoutes.index.path,
                    render: <ApiKeyIndex />,
                    text: "API keys",
                    url: "clients.apiKey.index",
                    urlParams: {id: this.props.match.params.id},
                },
            });
        }

        if (clientData) {
            const productHasEventBookingSupport = clientData.clientProducts.some(product => product.product.has_event_booking_support);

            if (!productHasEventBookingSupport) {
                delete tabs[eventBookingsRoutes.index.path];

                if (active === eventBookingsRoutes.index.path) {
                    const returnLink = getLink('clients.edit', {id: clientData.id });

                    showModal({
                        isVisible: true,
                        content: renderModalError('Event bookings', returnLink, 'Client event bookings was not found'),
                    });
                }
            }
        }

        return {
            active: active,
            tabs: tabs,
        };
    };

    renderHeaderTitle = () => {
        if (!_isUndefined(this.props.match.params.id)) {
            return (
                !_isUndefined(this.props.clientData)
                && !_isUndefined(this.props.clientData.name)
                && this.props.clientData.name !== ""
            )
                ? 'Client "' + this.props.clientData.name + '"'
                : 'Client';
        } else {
            return 'Add new client';
        }
    };

    render() {
        return (
            <div>
                <HeaderRenderer
                    buttons={ClientTabsButtons}
                    buttonsProps={{clientId: convertToInt(this.props.match.params.id), loading: this.state.loading}}
                    pageTitle={this.renderHeaderTitle()}
                />
                <div className="clearfix"/>
                <MessageBox name="clientMessage"/>
                {(this.state.renderTabs || this.props.match.path === routes.clients.add.path)
                && <Tabs active={this.state.active} items={this.state.tabs}/>}
            </div>
        );
    }
}

export const ClientForStore = gql(clientGraphQl.clientForStore);

export const prepareClientForStore = (client) => {
    const clientProducts = [];

    for (let i = 0; i < client.client_products.length; i++) {
        if (PRODUCT_THIRD_PARTY !== convertToInt(client.client_products[i].product.id)) {
            clientProducts.push(_omit(client.client_products[i], ['third_party_client_product']));
        } else {
            clientProducts.push(client.client_products[i]);
        }
    }

    return {
        name: 'client',
        data: {
            api_key: client.api_key,
            clientProducts: clientProducts,
            id: client.id,
            name: client.name,
            bookmaker: client.bookmaker,
            clientDrmSetup: client.client_drm_setup,
        },
    };
};

const mapStateToProps = (state) => {
    return {
        modal: state.modal,
        clientData: state.app.entities.client,
        userPrivileges: state.app.security.user.privileges,
    };
};

const mapDispatchToProps = mapModulesToProps(['Entity', 'MessageBox', 'Modal']);

export default withRouter(withApollo(connect(mapStateToProps, mapDispatchToProps)(ClientTabsLayout)));
