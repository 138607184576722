export const AKAMAI_LIVE_STREAM_NAME = 50;
export const AV_COMPETITOR = 32;
export const BLACKOUT_ZONE = 66;
export const BOOKING = 31;
export const CHANGELOG = 49;
export const CLIENT = 9;
export const CLIENT_ANALYTICS_SETUP = 41;
export const CLIENT_API_KEY = 53;
export const CLIENT_CDN_CONFIGURATION = 44;
export const CLIENT_LABEL = 48;
export const CLIENT_LCP_P_NOTIFICATION = 64;
export const CLIENT_PACKAGE = 12;
export const CLIENT_PACKAGE_GEO_RESTRICTION = 13;
export const CLIENT_PACKAGE_CONTENT = 14;
export const CLIENT_PRODUCT = 11;
export const CLIENT_PRODUCT_GEOLOCATION_COUNTRY = 60;
export const GEOLOCATION_CHECK_METHOD_COORDINATES = 2;
export const CLIENT_WHITELISTED_IP = 10;
export const CLIENT_WHITELISTED_IP_AV_API_ACCESS = 80;
export const CLIP_CONFIGURATION = 76;
export const CONTACT_PERSON = 2;
export const CONTENT_CATEGORY = 5;
export const COUNTRY = 67;
export const DISTRIBUTION = 37;
export const DRM_SETUP = 59;
export const CLIENT_VPL_LICENCE_KEY = 81;
export const EVENT = 25;
export const EVENT_CONTENT = 26;
export const EVENT_CONTENT_ALERT = 43;
export const EVENT_CONTENT_TYPE = 34;
export const EVENT_BOOKING = 75;
export const EVENT_GEO_RESTRICTION = 29;
export const EVENT_GROUP_NOTIFICATION = 56;
export const EVENT_INCIDENT = 46;
export const EVENT_INVOICE_STATUS = 38;
export const ENCODING_JOB_PROFILE = 35;
export const EVENT_REPORT = 40;
export const EVENT_STREAM_CONSTRAINT = 30;
export const ENCODING_TARGET = 27 ;
export const BLACKLISTED_IP = 61;
export const LICENSEE = 3;
export const LICENSOR = 1;
export const LCR_CHANNEL = 68;
export const MASTER_BOOKING = 51;
export const MASTER_EVENT_NOTIFICATION = 55;
export const MATCH_SELECTION_PERIOD = 7;
export const MEDIA_CONNECT_ORIGIN_ENTRY_POINT = 74;
export const OFFERING = 54;
export const OVERLAY = 78;
export const PROPERTY_LICENCE = 6;
export const PROPERTY_LICENCE_CONTENT = 65;
export const PRODUCT = 39;
export const PROPERTY_LICENCE_TECH_SETUP = 36;
export const PROPERTY_LICENCE_STREAM_CONSTRAINT = 52;
export const PRODUCTION_COMPANY = 15;
export const RIGHT_SCOPE = 8;
export const RTMP_ORIGIN_STREAM_NAME = 16;
export const RTMP_ORIGIN_ENTRY_POINT = 17;
export const RTMP_ORIGIN_ENTRY_POINT_APPLICATION = 18;
export const SDI_ORIGIN_ENTRY_POINTS = 19;
export const THIRD_PARTY_CLIENT_CDN = 21;
export const THIRD_PARTY_CLIENT_PULL_ORIGIN = 22;
export const THIRD_PARTY_CLIENT_PULL_STREAM_NAME = 24;
export const THIRD_PARTY_CLIENT_PUSH_STREAM_NAME = 23;
export const THIRD_PARTY_CLIENT_VOD_STORAGE = 58;
export const STREAM = 28;
export const UDP_ORIGIN_ENTRY_POINT = 20;
export const USER = 4;
export const USER_ACCESS_INFORMATION = 77;
export const USER_SETTINGS = 63;
export const WNS_STREAM = 71;
export const VENUE = 33;
export const LIVE_CONTROL = 42;
export const EVENT_PRESET = 45;
export const SLATE = 47;
export const ROLE = 62;
export const AUDIO = 72;
export const SUBTITLE = 73;
export const AD_SPLICE_CONFIG = 79;
