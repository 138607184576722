import validators from '@utils/validators';

const validate = (values) => {
    const errors = {};

    Object.assign(errors, validators.required(values.name, 'name'));

    if ('price_option' in values && '' !== values.price_option && 'remove_price' !== values.price_option) {
        Object.assign(errors, validators.floatAndNumbersWithFixedDecimal({value: values.value}, 'value'));

        if ('decrease_price' === values.price_option) {
            Object.assign(errors, validators.rangeFloat({value: values.value, min: 0.1, max: 100}, 'value'));
        } else {
            Object.assign(errors, validators.rangeFloat({value: values.value, min: 0.1, max: 400}, 'value'));
        }
    }

    return errors;
};

export default validate;
