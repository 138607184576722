import React from 'react';
import PropTypes from 'prop-types';
import {Field, reduxForm} from 'redux-form';
import {Button, Header} from 'semantic-ui-react';
import {compose} from 'redux';
import {get as _get, orderBy as _orderBy} from 'lodash';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';

import {graphql} from 'react-apollo';
import Authorization from '@appComponents/Authorization';
import Form from '@appComponents/ReduxFormControls';
import {FormWrapper} from '@appComponents/HOCFormWrapper';
import {getLink} from '@appComponents/Link';
import * as CONST from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import * as MESSAGES from '@constants/messages';
import {convertToInt, defaultValuePrice, isUrlParamValid, isValidJiraTicketNumber} from '@utils/helpers';
import mapModulesToProps from '@utils/mapModulesToProps';
import {
    CreateClientPackageMutation,
    DeleteClientPackageMutation,
    UpdateClientPackageMutation,
} from '@graphql/clientPackage/mutation';
import ClientPackageContentFormExternalIcon from '@modules/client/components/ClientPackageContentFormExternalIcon';

import validate from './validatorClientPackage';


const CLIENT_PACKAGE = 'client package';

export class ClientPackageContentForm extends React.Component {
    static propTypes = {
        CreateClientPackage: PropTypes.func,
        clientPackage: PropTypes.object,
        dataForForm: PropTypes.object,
        dataMap: PropTypes.object,
        DeleteClientPackage: PropTypes.func,
        Entity: PropTypes.object,
        handleSubmit: PropTypes.func,
        history: PropTypes.object,
        loading: PropTypes.bool,
        match: PropTypes.object,
        MessageBox: PropTypes.object,
        Modal: PropTypes.object,
        submitting: PropTypes.bool,
        setLoading: PropTypes.func,
        UpdateClientPackage: PropTypes.func,
    };

    constructor(props) {
        super(props);
        const {clientPackage} = props.dataForForm;

        this.state = {
            has_additional_cdn_bucket_active:
                (clientPackage.id && clientPackage.has_additional_cdn_bucket !== undefined)
                    ? null === clientPackage.has_additional_cdn_bucket
                    : true,
            has_additional_cdn_bucket: clientPackage.id ? clientPackage.has_additional_cdn_bucket : undefined,
            cdn_price_active: clientPackage.id ? null === clientPackage.cdn_price : true,
            cdn_price: null,
            clientProductsList: [],
            isClientPackageTypeMSM: false,
            hasEventBookingSupport: false,
        };
    }

    componentWillReceiveProps(nextProps) {
        const {clientPackage, clientProducts} = nextProps.dataForForm;
        const propsForForm = {};

        if (!nextProps.submitting) {

            if (this.props.dataForForm.clientPackage.id !== clientPackage.id) {

                propsForForm.cdn_price_active = this.state.cdn_price_active && null === clientPackage.cdn_price;


                if (clientPackage.has_additional_cdn_bucket !== undefined) {
                    if (null === clientPackage.has_additional_cdn_bucket) {
                        propsForForm.has_additional_cdn_bucket_active = true;
                        propsForForm.has_additional_cdn_bucket = false;
                    } else {
                        propsForForm.has_additional_cdn_bucket_active = false;
                        propsForForm.has_additional_cdn_bucket = clientPackage.has_additional_cdn_bucket;
                    }
                }
            }

        }

        let clientProductsList = [];

        if (clientProducts) {
            clientProductsList = clientProducts.map((value) => (
                {
                    key: value.id,
                    value: value.id,
                    text: value.product.name,
                    has_event_booking_support: value.product.has_event_booking_support,
                    has_hq_support: value.product.has_hq_support,
                }
            ));
        }

        this.setState(() => ({
            ...propsForForm,
            clientProductsList: clientProductsList,
        }));
    }

    componentDidUpdate() {
        if (this.props.dataForForm.clientPackage.content_activation_ticket_number
            && !this.state.currentContentActivationTicketNumber
        ) {
            this.renderContentActivationTicketNumberDescription(this.props.dataForForm.clientPackage.content_activation_ticket_number);

            this.setState(() => ({
                currentContentActivationTicketNumber: this.props.dataForForm.clientPackage.content_activation_ticket_number,
            }));
        }
    }

    deleteClientPackage = (id) => {
        this.props.Modal.setModalConfirmation({
            text: MESSAGES.DELETING(CLIENT_PACKAGE, this.props.dataForForm.clientPackage.name),
        });

        return this.props.DeleteClientPackage({
            variables: {
                id: id,
            },
        }).then(() => {
            this.props.MessageBox.addMessage(
                'clientMessage',
                MESSAGES.DELETED(CLIENT_PACKAGE, this.props.dataForForm.clientPackage.name),
                null,
                'success'
            );
            this.props.Modal.setModal({isVisible: false});
            this.props.history.push(getLink('clients.packages.index',{id: this.props.match.params.id}));
        }).catch((error) => {
            this.props.Modal.setModal({isVisible: false});
            this.props.MessageBox.addMessage(
                'clientMessage',
                MESSAGES.NOT_DELETED(CLIENT_PACKAGE, this.props.dataForForm.clientPackage.name),
                `${error}`,
                'error'
            );
        });
    };

    deleteButtonClick = (event) => {
        event.preventDefault();
        const data = this.props.dataForForm.clientPackage;

        this.props.Modal.setModalConfirmation({
            header: <Header icon='trash' content={MESSAGES.DELETE_HEADER(CLIENT_PACKAGE)}/>,
            text: MESSAGES.DELETE_CONFIRMATION(CLIENT_PACKAGE, this.props.dataForForm.clientPackage.name),
            onYes: () => (this.deleteClientPackage(parseInt(data.id))),
        });
    };

    renderDeleteButton = () => {
        let deleteButton = null;

        if (this.props.dataForForm.clientPackage.id !== undefined && '' !== this.props.dataForForm.clientPackage.id) {
            deleteButton =
                <Button onClick={ this.deleteButtonClick }
                    color='red'
                    disabled={ this.props.submitting }
                    icon='trash'
                    content='Delete'
                />;
        }

        return deleteButton;
    };

    formSubmit = (data) => {
        let dataToSave,
            dataMap = Object.assign({}, this.props.dataMap.clientPackage, data);

        dataToSave = Object.assign(dataMap, {
            client_product: parseInt(dataMap.client_product),
            client_package_type: parseInt(dataMap.client_package_type),
            is_hq: this.state.hasHqSupport ? dataMap.is_hq : null,
            booking_type: parseInt(dataMap.booking_type),
            event_booking_type: (this.state.hasEventBookingSupport) ? parseInt(dataMap.event_booking_type) : null,
            content_package_selection_type: parseInt(dataMap.content_package_selection_type),
            cdn_price: (data.cdn_price_active ) ? null : parseFloat(dataMap.cdn_price),
            has_additional_cdn_bucket: (dataMap.has_additional_cdn_bucket_active)
                ? null : dataMap.has_additional_cdn_bucket,
            additional_cdn_bucket_size_gb: (dataMap.has_additional_cdn_bucket_active
                || !dataMap.has_additional_cdn_bucket)
                ? null : parseInt(dataMap.additional_cdn_bucket_size_gb),
            minimum_spend_commitment: (
                CONST.CLIENT_PACKAGE_TYPE_MSM === convertToInt(data.client_package_type)
                && dataMap.minimum_spend_commitment
            )
                ? parseFloat(dataMap.minimum_spend_commitment)
                : null,
        });

        let result;

        if (dataToSave.id === undefined || '' === dataToSave.id) {
            result = this.props.CreateClientPackage({
                variables: {...dataToSave},
            }).then((result) => {
                this.props.MessageBox.addMessage(
                    'clientMessage',
                    MESSAGES.SAVED(CLIENT_PACKAGE, data.name),
                    null,
                    'success'
                );

                this.props.Entity.setEntity({
                    name: 'clientPackage',
                    data: {...this.props.clientPackage, ...{name: dataToSave.name}, ...result.data.createClientPackage},
                });

                this.props.history.push(getLink('clients.packages.edit', {
                    id: this.props.match.params.id,
                    packagesId: result.data.createClientPackage.id,
                }));
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    'clientMessage',
                    MESSAGES.NOT_SAVED(CLIENT_PACKAGE, data.name),
                    `${error}`,
                    'error'
                );
            });
        } else {
            result = this.props.UpdateClientPackage({
                variables: dataToSave,
            }).then(() => {
                this.props.MessageBox.addMessage(
                    'clientMessage',
                    MESSAGES.SAVED(CLIENT_PACKAGE, this.props.dataForForm.clientPackage.name),
                    null,
                    'success'
                );
                this.props.Entity.setEntity({
                    name: 'clientPackage',
                    data: {...this.props.clientPackage, ...{name: dataToSave.name}},
                });
            }).catch((error) => {
                this.props.MessageBox.addMessage(
                    'clientMessage',
                    MESSAGES.NOT_SAVED(CLIENT_PACKAGE, this.props.dataForForm.clientPackage.name),
                    `${error}`,
                    'error'
                );
            });
        }

        return result;
    };

    toggleOption = (data, props) => {
        this.setState(() => ({
            [props.input.name]: data.checked,
        }));
    };

    onChangeClientPackageType = (data) => {
        this.setState(() => ({isClientPackageTypeMSM: CONST.CLIENT_PACKAGE_TYPE_MSM === convertToInt(data)}));
    };

    onChangeProduct = (data) => {
        const hasEventBookingSupport = this.state.clientProductsList
            .filter((product) => product.key === data)[0]?.has_event_booking_support;
        const productHasHqSupport = this.state.clientProductsList
            .filter((product) => product.key === data)[0]?.has_hq_support;

        this.setState(() => ({
            selectedProductId: data,
            hasEventBookingSupport: hasEventBookingSupport,
            hasHqSupport: productHasHqSupport,
        }));
    };

    onChangeContentActivationTicketNumber = (data) => {
        const inputData = data?.currentTarget?.value;

        this.setState(() => ({
            currentContentActivationTicketNumber: inputData,
        }));
    }

    renderContentActivationTicketNumberDescription = (ticketNumber) => {
        return isValidJiraTicketNumber(ticketNumber)
            ? <ClientPackageContentFormExternalIcon ticketNumber={ticketNumber.toUpperCase()}/>
            : null;
    }

    render() {
        const filteredBookingTypes = this.props.dataForForm.bookingTypes.filter((type) => 3 == type.id || 4 == type.id ? false : true);
        const productHasHq = this.state.clientProductsList.findIndex((product) =>
                (this.state.selectedProductId === product.value && product.has_hq_support)
            ),
            displayHqCheckbox = Boolean(productHasHq > -1);

        const handleOptionsForContentVariants = () => {
            const { dataForForm } = this.props;

            if (dataForForm.clientProducts.length
                && this.state.selectedProductId
                && isUrlParamValid(this.props.match.params.id)) {
                const selectedProduct = dataForForm.clientProducts.find(
                        (clientProduct) => clientProduct.id === this.state.selectedProductId
                    ),
                    allowedEventContentVariantIds = selectedProduct.product.product_event_content_variants.map(
                        (variant) => variant.event_content_variant.id
                    );

                return _orderBy(dataForForm.eventContentVariants.filter((eventContentVariant) =>
                    allowedEventContentVariantIds.includes(eventContentVariant.id)
                ), (row) => (row.id), ['asc']);
            }

            return [];
        };

        const handleDefaultValuesForContentVariants = () => {
            const { dataForForm } = this.props;

            const clientPackageEventContentVariants = _get(dataForForm, 'clientPackage.event_content_variants', []);

            if (clientPackageEventContentVariants.length) {
                return clientPackageEventContentVariants.map(
                    (eventContentVariant) => Number(eventContentVariant.id)
                );
            }

            if (
                this.state.selectedProductId
                && dataForForm.clientProducts.length
                && dataForForm.clientPackage.client_product.id !== this.state.selectedProductId
            ) {
                const selectedProduct = dataForForm.clientProducts.find(
                        (clientProduct) => clientProduct.id === this.state.selectedProductId
                    ), defaultContentVariants = selectedProduct.product.product_event_content_variants.filter(
                        (variant) => variant.is_default
                    );

                return defaultContentVariants.map((variant) => Number(variant.event_content_variant.id));
            }

            return [];
        };

        return (
            <Form.Create
                loading={this.props.loading}
                onSubmit={this.props.handleSubmit(this.formSubmit)}
            >
                <Form.IdField
                    defaultValue={this.props.dataForForm.clientPackage.id}
                />
                <Form.FormRow
                    component={Form.SemanticSelect}
                    options={this.state.clientProductsList}
                    disabled={(this.props.dataForForm.clientPackage.client_product.id) ? true : false}
                    label='Product'
                    required
                    defaultValue={this.props.dataForForm.clientPackage.client_product.id}
                    name='client_product'
                    type='text'
                    onChange={this.onChangeProduct}
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.clientPackage.name}
                    label='Name'
                    required
                    name='name'
                    type='text'
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.clientPackage.description}
                    label='Description'
                    name='description'
                    type='text'
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.clientPackage.unique_sportradar_contract_number}
                    label='Unique Sportradar contract number'
                    name='unique_sportradar_contract_number'
                    type='text'
                />
                <Form.FormRow
                    defaultValue={this.props.dataForForm.clientPackage.content_activation_ticket_number}
                    component={Form.SemanticInput}
                    label='Content activation ticket number'
                    name='content_activation_ticket_number'
                    type='text'
                    maxlength={CONST.CONTENT_ACTIVATION_TICKET_NUMBER_MAX_LENGTH}
                    description={this.renderContentActivationTicketNumberDescription(this.state.currentContentActivationTicketNumber)}
                    onChange={this.onChangeContentActivationTicketNumber}
                />
                <Form.FormRow
                    defaultValue={this.props.dataForForm.clientPackage.sf_contract_number}
                    component={Form.SemanticInput}
                    label='SF contract number'
                    name='sf_contract_number'
                    type='text'
                />
                <Form.FormRow
                    component={Form.SemanticSelect}
                    options={this.props.dataForForm.clientPackageTypes}
                    defaultValue={this.props.dataForForm.clientPackage.client_package_type.id}
                    label='Package type'
                    required
                    name='client_package_type'
                    type='text'
                    onChange={this.onChangeClientPackageType}
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={defaultValuePrice(this.props.dataForForm.clientPackage.minimum_spend_commitment)}
                    hidden={!this.state.isClientPackageTypeMSM}
                    label='Minimum spend commitment'
                    labelDescription={{content: '€'}}
                    max={CONST.MINIMUM_SPEND_COMMITMENT_MAX}
                    maxlength={CONST.MINIMUM_SPEND_COMMITMENT_MAX_LENGTH}
                    min={CONST.MINIMUM_SPEND_COMMITMENT_MIN}
                    name='minimum_spend_commitment'
                    step={CONST.MINIMUM_SPEND_COMMITMENT_STEP}
                    type='number'
                />
                {
                    this.state.hasEventBookingSupport &&
                    <Form.FormRow
                        component={Form.SemanticSelect}
                        label='Event booking type'
                        options={filteredBookingTypes}
                        defaultValue={this.props.dataForForm.clientPackage.event_booking_type.id}
                        required
                        name='event_booking_type'
                        type='text'
                    />
                }
                <Form.FormRow
                    component={Form.SemanticSelect}
                    label={this.props.dataForForm.clientPackage.client_product.product.has_event_booking_support || this.state.hasEventBookingSupport
                        ? 'Distribution booking type'
                        : 'Booking type'}
                    options={filteredBookingTypes}
                    defaultValue={this.props.dataForForm.clientPackage.booking_type.id}
                    required
                    name='booking_type'
                    type='text'
                />
                <Form.FormRow
                    component={Form.SemanticSelect}
                    label='Content selection'
                    required
                    disabled={(this.props.dataForForm.clientPackage.client_product.id) ? true : false}
                    defaultValue={this.props.dataForForm.clientPackage.content_package_selection_type.id}
                    options={this.props.dataForForm.contentPackageSelectionTypes}
                    name='content_package_selection_type'
                    type='text'
                />
                <div
                    className='checkboxes-inline'>
                    <Form.FormRow
                        component={Form.SemanticCheckboxList}
                        label='Content variants'
                        required
                        defaultValue={handleDefaultValuesForContentVariants()}
                        options={handleOptionsForContentVariants()}
                        hidden={!this.state.selectedProductId}
                        name='event_content_variants'
                    />
                </div>
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.clientPackage.is_premium}
                    label='Premium'
                    name='is_premium'
                    type='checkbox'
                />
                {displayHqCheckbox && <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.clientPackage.is_hq}
                    label='High Quality'
                    name='is_hq'
                    type='checkbox'
                />}
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.clientPackage.is_test}
                    label='Test package'
                    name='is_test'
                    type='checkbox'
                />
                <Form.InlineDateGroup>
                    <Form.FormRow
                        className='--start_date'
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.clientPackage.start_date}
                        label='Start date'
                        name='start_date'
                        required
                        type='date'
                    />
                    <Form.FormRow
                        className='--end_date'
                        component={Form.SemanticInput}
                        defaultValue={this.props.dataForForm.clientPackage.end_date}
                        label='End date'
                        name='end_date'
                        required
                        type='date'
                    />
                </Form.InlineDateGroup>
                <Form.FormRow
                    component={Form.SemanticInput}
                    checked={this.state.cdn_price_active}
                    onCheckboxChange={this.toggleOption}
                    label='CDN price'
                    description='Same as product settings'
                    name='cdn_price_active'
                    type='toggle'
                />
                {
                    !this.state.cdn_price_active &&
                    <div className='formRow --emptyLabel'>
                        <Field
                            component={Form.SemanticInput}
                            defaultValue={defaultValuePrice(this.props.dataForForm.clientPackage.cdn_price)}
                            labelDescription={{content: '€'}}
                            name='cdn_price'
                            description='€'
                            required
                            type='number'
                        />
                    </div>
                }
                <Form.FormRow
                    component={Form.SemanticInput}
                    checked={this.state.has_additional_cdn_bucket_active}
                    defaultValue={this.state.has_additional_cdn_bucket_active}
                    label='Additional CDN bucket'
                    description='Same as product settings'
                    onCheckboxChange={this.toggleOption}
                    name='has_additional_cdn_bucket_active'
                    type='toggle'
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={this.props.dataForForm.clientPackage.has_additional_cdn_bucket}
                    label='Add additional CDN bucket'
                    onCheckboxChange={this.toggleOption}
                    name='has_additional_cdn_bucket'
                    type='checkbox'
                    hidden={this.state.has_additional_cdn_bucket_active}
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={(this.props.dataForForm.clientPackage.additional_cdn_bucket_size_gb)}
                    label='Additional CDN bucket size'
                    labelDescription={{content: 'Gb'}}
                    name='additional_cdn_bucket_size_gb'
                    required
                    type='number'
                    min={1}
                    hidden={this.state.has_additional_cdn_bucket_active || !this.state.has_additional_cdn_bucket}
                />
                <Form.FormRow
                    component={Form.SemanticInput}
                    defaultValue={(this.props.dataForForm.clientPackage.is_disabled)}
                    label='Disabled'
                    name='is_disabled'
                    type='checkbox'
                />
                <Form.FormRowChangelog
                    resources={RESOURCES.CLIENT_PACKAGE}
                    data={this.props.dataForForm.clientPackage}
                />
                <div className='formRow form__footer'>
                    <label/>
                    <Authorization
                        privileges={CONST.SECURITY_PRIVILEGES_UPDATE}
                        resources={RESOURCES.CLIENT_PACKAGE}
                    >
                        <Button
                            color='blue'
                            type='submit'
                            loading={ this.props.submitting }
                            disabled={ this.props.submitting }
                            icon='save'
                            content='Save'>
                        </Button>
                    </Authorization>
                    <Authorization
                        privileges={CONST.SECURITY_PRIVILEGES_DELETE}
                        resources={RESOURCES.CLIENT_PACKAGE}
                    >
                        { this.renderDeleteButton() }
                    </Authorization>
                </div>
            </Form.Create>
        );
    }
}

const ClientPackageContentFormWithRedux = reduxForm({form: 'ClientPackageForm', validate})(ClientPackageContentForm);
const mapDispatchToProps = mapModulesToProps(['Entity']);
const mapStateToProps = (state) => {
    const clientPackage = state.app.entities.clientPackage || {};

    return {clientPackage: clientPackage};
};

export default compose(
    graphql(CreateClientPackageMutation, {name: 'CreateClientPackage'}),
    graphql(UpdateClientPackageMutation, {name: 'UpdateClientPackage'}),
    graphql(DeleteClientPackageMutation, {name: 'DeleteClientPackage'})
)( withRouter(FormWrapper({
    clientPackage: {
        id: '',
        name: '',
        description: '',
        unique_sportradar_contract_number: '',
        content_activation_ticket_number: '',
        sf_contract_number: '',
        client_package_type: {
            id : '',
        },
        minimum_spend_commitment: '',
        booking_type: {
            id: '',
        },
        event_booking_type: {
            id: '',
        },
        content_package_selection_type: {
            id: '',
        },
        event_content_variants: {
            id: '',
        },
        client_product: {
            id: '',
            product: {
                id : '',
                has_event_booking_support: '',
            },
        },
        is_premium: false,
        is_hq: false,
        is_test: false,
        start_date: '',
        end_date: '',
        cdn_price: null,
        cdn_price_active: true,
        has_additional_cdn_bucket: false,
        additional_cdn_bucket_size_gb: '',
        is_disabled: false,
        creation_datetime: '',
        creation_user: {
            id: '',
            name: '',
        },
        update_datetime: '',
        update_user: {
            id: '',
            name: '',
        },
    },
    clientProducts: [],
    clientPackageTypes: [],
    bookingTypes: [],
    contentPackageSelectionTypes: [],
    eventContentVariants: [],

}, connect(mapStateToProps, mapDispatchToProps)(ClientPackageContentFormWithRedux))));
