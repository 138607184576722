import React from 'react';
import {get as _get, union as _union} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import {Icon} from 'semantic-ui-react';

import DefaultForm from '@appComponents/DefaultForm';
import {
    EVENT_CONTENT_BOOKED_BY_CLIENT,
    EVENT_CONTENT_OFFERED_BY_CLIENT,
    PRODUCT_LCO,
} from '@constants/variables';
import {convertToInt} from '@utils/helpers';

export default class NotificationForm extends DefaultForm {
    static propTypes = {
        ...super.propTypes,
        startDatetime: PropTypes.string
    };

    static defaultProps = {
        ...super.defaultProps,
        startDatetime: null
    };

    prepareDataForSubmit(data) {
        let dataToSave = {
            additional_recipients: data.additional_recipients || [],
            description: data.description,
            message: data.message,
            recipients: this.state.recipients
        };

        return dataToSave;
    }

    checkRecipients = (
        additional_recipients = _get(this.props, 'formValues.additional_recipients', [])
    ) => {
        let isFormSubmitDisabled = (
            0 === additional_recipients.length
            && 0 === _get(this.state, 'recipients', []).length
        );

        this.setState(() => ({
            defaultForm_disableSubmit: isFormSubmitDisabled
        }));
    };

    setRecipientType = (startDatetime) => {
        let preselectTypes = [EVENT_CONTENT_BOOKED_BY_CLIENT];

        if (startDatetime && moment(startDatetime).isAfter(moment().add('30', 'm'))) {
            preselectTypes.push(EVENT_CONTENT_OFFERED_BY_CLIENT);
        }

        this.setField('recipient_type', {
            defaultValue: preselectTypes
        })
    };

    getSelectedClients = (clientsArray) => {
        this.setState(() => ({
            selectedClients: clientsArray,
            recipients: clientsArray
        }), this.checkRecipients);

        this.setField('recipients', {
            selected: clientsArray
        })
    };

    setClientSelection = (props = this.props) => {
        let clientsSelection = [];

        props.formValues.recipient_type.forEach((recipient_type) => {
            let clients = _get(this.state.clientDistributionsObject, `${recipient_type}` , []);

            clientsSelection = _union(clientsSelection, clients);
        });

        this.setField('recipients', {selected: clientsSelection});
        this.setState(() => ({
            recipients: clientsSelection,
            defaultForm_disableSubmit: !(
                0 < clientsSelection.length
                || 0 < _get(props, "formValues.additional_recipients", []).length
            )
        }));
    };

    componentDidMount() {
        this.setRecipientType(this.props.eventStartDatetime);

        this.setField('recipients', {
            getSelectedClients: this.getSelectedClients
        });

        this.setOnChangeCallback({
            recipient_type: (data) => {
                this.setState(() => ({
                    recipientsType: data.value
                }), this.setClientSelection);
            },
            additional_recipients: (data) => {
                this.checkRecipients(data.value);
            }
        })
    }

    componentWillReceiveProps(nextProps) {
        super.componentWillReceiveProps(nextProps);

        const loading = _get(nextProps, 'GraphQLOptionsData.loading', true);

        if (loading) {
            return;
        }

        if (0 < nextProps.formValues.recipient_type.length
            && !this.state.clientsSelected
        ) {
            this.setClientSelection(nextProps);
            this.setState(() => ({clientsSelected: true}));
        }

        if (!this.state.distributionObjectCreated) {
            let clientsList = _get(nextProps, "GraphQLOptionsData.clientsBookingsAndOfferings", null);

            if (!clientsList) {
                return
            }

            this.setState(() => ({
                distributionObjectCreated: true,
                clientDistributionsObject: this.updateDistributionsObject(clientsList),
            }), this.setClientSelection);
        }
    }

    renderSaveButton() {
        const saveButtonProps = {
            content: "Send",
            icon: <Icon className='outline' name='mail' />
        };

        return super.renderSaveButton(saveButtonProps);
    }

    updateDistributionsObject(clientsList) {
        let distributionsObject = {
            [EVENT_CONTENT_BOOKED_BY_CLIENT]: [],
            [EVENT_CONTENT_OFFERED_BY_CLIENT]: [],
        };

        clientsList.forEach((client) => {
            if (client.bookings && client.bookings.some(booking => PRODUCT_LCO === convertToInt(booking.product))) {
                distributionsObject[EVENT_CONTENT_BOOKED_BY_CLIENT] =
                    _union(distributionsObject[EVENT_CONTENT_BOOKED_BY_CLIENT], [client.id]);
            }

            if (client.offerings && client.offerings.some(offering => PRODUCT_LCO === convertToInt(offering.product))) {
                distributionsObject[EVENT_CONTENT_OFFERED_BY_CLIENT] =
                    _union(distributionsObject[EVENT_CONTENT_OFFERED_BY_CLIENT], [client.id]);
            }
        });

        return distributionsObject;
    }
}
