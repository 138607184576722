import React from 'react';
import PropTypes from 'prop-types';

import Table from '@appComponents/Table';

import {
    columns,
    getTableRowExpiredDateClassName,
    rowRenderer,
} from '../utils/clientVideoPlayerIndexTable';

const ClientVideoPlayerIndexTable = ({clientId, loading, data}) => {
    return (
        <Table
            columns={columns}
            data={data}
            name='clientVideoPlayerIndexList'
            noDataText='No licence keys found'
            loading={loading}
            defaultSort={'licence_key'}
            pagination={false}
            rowRenderer={rowRenderer}
            rowRendererProps={{clientId: clientId}}
            rowClassname={getTableRowExpiredDateClassName}
        />
    );
};

ClientVideoPlayerIndexTable.propTypes = {
    clientId: PropTypes.number.isRequired,
    loading: PropTypes.bool.isRequired,
    data: PropTypes.array.isRequired,
};
export default ClientVideoPlayerIndexTable;
