import moment from 'moment/moment';
import {get as _get, isEmpty as _isEmpty} from 'lodash';

import {showErrorModal} from '@utils/modal';
import {dateEmpty} from '@utils/date';

const ALLOWED_DAYS = 31;

export const noRequiredFilters = (from, to, filters) => {
    const fromDate = moment(from, 'YYYY-MM-DD');
    const toDate = moment(to, 'YYYY-MM-DD'),
        differenceDays = moment.duration(toDate.diff(fromDate)).asDays(),
        tournament = _get(filters, 'tournament', []),
        contentCategoryLevel3 = _get(filters, 'contentCategoryLevel3', []);

    return differenceDays > ALLOWED_DAYS
        && _isEmpty(tournament)
        && _isEmpty(contentCategoryLevel3);
};

export const skipSearch = (props) => {
    const from = _get(props, 'filters.from', null),
        to = _get(props, 'filters.to', null);

    return dateEmpty(from, to) || noRequiredFilters(from, to, props.filters);
};

export const showConstraintsModal = (data) => {
    const headerText = 'Error with filters';

    if (dateEmpty(data.from, data.to)) {
        showErrorModal({
            header: headerText,
            text: 'You must enter a start time and an end time.',
        });
    }

    if (noRequiredFilters(data.from, data.to, data)) {
        showErrorModal({
            header: headerText,
            text: 'If you use a time range larger than ' + ALLOWED_DAYS + ' days, you also have to select an option '
                + 'in at least one of the following filters: "Tournament", "3rd level category".',
        });
    }
};
