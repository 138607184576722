import {
    get as _get,
    has as _has,
    isEqual as _isEqual,
    uniqWith as _uniqWith,
    isEmpty as _isEmpty,
} from 'lodash';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import {Label} from 'semantic-ui-react';

import {renderEventStatusName} from '@utils/status';
import {IconLinkify} from '@appComponents/IconCollection';
import {getCompetitorsNameFromEvent} from '@utils/competitorName';
import {addHasMatchIdToStructure} from '@utils/eventObject';
import {convertToInt, getParsedId, getProductShortNameWithOnlyLettersAndDigits} from '@utils/helpers';
import {
    COMPETITOR_NOT_DEFINED,
    COMPETITOR_NOT_DEFINED_BOTH,
    PROPERTY_LICENCE_TYPE_OTHER,
    PROPERTY_LICENCE_TYPE_SPORTRADAR,
    SECURITY_PRIVILEGES_READ,
} from '@constants/variables';
import * as RESOURCES from '@constants/resources';
import Table from '@appComponents/Table';
import LinkWithAuthorization from '@appComponents/LinkWithAuthorization';

const undefinedCompetitorsText = COMPETITOR_NOT_DEFINED_BOTH;

export const name = (home, away) => (
    (home || away) ?
        `${home ? home.name : COMPETITOR_NOT_DEFINED} : ${away ? away.name : COMPETITOR_NOT_DEFINED}` :
        undefinedCompetitorsText
);

export const PropertyLicenceEventsTable = (props) => {
    const {loading, propertyLicenceEvents, propertyLicenceTypeId} = props;

    let columns = {
        'id': {
            label: 'ID',
            sorting: (row) => getParsedId(row),
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
        'match_id': {
            label: 'Match ID',
            sorting: (row) => {
                const matchId = _get(row, 'sr_event.match.id', null);

                return matchId ? parseInt(matchId, 10) : null;
            },
            isHidden: propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_SPORTRADAR,
            header: {
                align: 'center',
                isCollapsed: true,
            },
            content: {
                align: 'center',
            },
        },
        'start_datetime': {
            label: 'Date/time',
            sorting: true,
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
                singleLine: true,
            },
        },
        'category': {
            label: 'Category',
            sorting: row => renderCategoryColumn(row),
            isHidden: propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_SPORTRADAR,
        },
        'content_category_level2': {
            label: '2nd level category',
            sorting: row => renderContentCategoryColumn(row, 2),
            isHidden: propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_OTHER,
        },
        'tournament': {
            label: 'Tournament',
            sorting: row => renderTournamentColumn(row),
            isHidden: propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_SPORTRADAR,
        },
        'content_category_level3': {
            label: '3rd level category',
            sorting: row => renderContentCategoryColumn(row, 3),
            isHidden: propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_OTHER,
        },
        'competitors': {
            label: 'Competitors / Description',
            sorting: row => row.competitorsSortValue,
        },
        'event_status': {
            label: 'Status',
            sorting: true,
        },
        'products': {label: 'Products', content: {singleLine: true}},
        'bookings': {
            label: 'Bookings',
            sorting: (row) => parseInt(row.bookings, 10),
            header: {
                align: 'center',
            },
            content: {
                align: 'center',
            },
        },
        'origin_stream_status': {
            label: 'Origin stream status',
            sorting: true,
        },
        'issue_types': {
            label: 'Issue types',
            sorting: (row) => renderIssueTypesColumn(row),
        },
        'comment': {
            label: 'Comment',
            sorting: true,
        },
    };

    if (0 < propertyLicenceEvents.length) {
        propertyLicenceEvents[0].products_invoice_status.forEach((productInvoiceStatus) => {
            Object.assign(columns, {
                [`invoice_status_${getProductShortNameWithOnlyLettersAndDigits(productInvoiceStatus.short_name)}`]: {
                    isHidden: !productInvoiceStatus.has_usage,
                    label: `Invoice status ${productInvoiceStatus.short_name}`,
                    sorting: true,
                },
            });
        });
    }

    const renderIdColumn = (column, row) => {
        const eventId = parseInt(row[column], 10);

        return (
            <div>
                {(_isEmpty(row[column]))
                    ? ''
                    :
                    <LinkWithAuthorization
                        authorization={{[RESOURCES.EVENT]: SECURITY_PRIVILEGES_READ}}
                        link={{
                            name: 'events.configuration.index',
                            params: {id: eventId},
                        }}
                    >
                        {eventId.toString()}
                    </LinkWithAuthorization>
                }
            </div>
        );
    };

    const renderMatchIdColumn = (column, row) => {
        const linkIcon = row.sr_event ? <IconLinkify color='red'/> : '';

        return (
            <div>
                {(!_has(row, 'sr_event.match.id'))
                    ? linkIcon
                    : parseInt(row.sr_event.match.id, 10)
                }
            </div>
        );
    };

    const renderStartDatetimeColumn = (column, row) => {
        return (
            <div>
                <div>
                    {_isEmpty(row[column])
                        ? ''
                        : moment(row[column]).format('YYYY-MM-DD HH:mm')
                    }
                </div>
                <div>
                    {_isEmpty(row.end_datetime)
                        ? ''
                        : moment(row.end_datetime).format('YYYY-MM-DD HH:mm')
                    }
                </div>
            </div>
        );
    };

    const renderCategoryColumn = (row) => {
        let categoryColumnContent = null;

        if (_has(row, 'sr_event.match.tournament.tournament_category.id')) {
            categoryColumnContent = row.sr_event.match.tournament.tournament_category.name;
        } else if (_has(row, 'sr_event.tournament.tournament_category.id')) {
            categoryColumnContent = row.sr_event.tournament.tournament_category.name;
        }

        return categoryColumnContent;
    };

    const renderTournamentColumn = (row) => {
        let tournamentColumnContent = null;

        if (_has(row, 'sr_event.match.tournament.id')) {
            tournamentColumnContent = row.sr_event.match.tournament.name;
        } else if (_has(row, 'sr_event.tournament.id')) {
            tournamentColumnContent = row.sr_event.tournament.name;
        }

        return tournamentColumnContent;
    };

    const renderContentCategoryColumn = (row, level) => {
        return (!_has(row, `av_event.content_category_level${level}.id`))
            ? ''
            : row.av_event[`content_category_level${level}`].name;
    };

    const renderDescription = (row) => {
        return row.description ?
            (
                <div>
                    <Label style={{backgroundColor: `#${row.color}`}} size='mini'>{row.description}</Label>
                </div>
            ) :
            null;
    };

    const renderCompetitorsColumn = (row) => {
        const data = addHasMatchIdToStructure(row),
            competitors = getCompetitorsNameFromEvent(data);

        return (
            <div>
                {competitors}
                {renderDescription(row)}
            </div>
        );

    };

    const renderStatusColumn = (column, row) => {
        return (
            <div>
                {(_has(row, 'event_status.id'))
                    ? renderEventStatusName(row.event_status.id, row.event_status.name)
                    : ''
                }
            </div>
        );
    };

    const renderProductsColumn = (column, row) => {
        let products = [];

        row.event_contents.map((event_content) => {
            event_content.products.map((product) => {
                products.push({
                    short_name: product.short_name,
                    id: product.product_id,
                });
            });
        });
        products.sort((a, b) => (convertToInt(a.id) > convertToInt(b.id) ? 1 : -1));

        const productsShortNames = products.map(product => product.short_name);

        return <div>{_uniqWith(productsShortNames, _isEqual).join(', ')}</div>;
    };

    const renderBookingsColumn = (column, row) => {
        return (
            <div>{row[column]}</div>
        );
    };

    const renderOriginStreamStatusColumn = (column, row) => {
        return (
            <div>
                {(!_has(row, 'origin_stream_status.id'))
                    ? ''
                    : row.origin_stream_status.name
                }
            </div>
        );
    };

    const renderCommentColumn = (column, row) => {
        return (
            <div className='--preWrap'>
                {_isEmpty(row[column])
                    ? ''
                    : row[column]
                }
            </div>
        );
    };

    const renderIssueTypesColumn = (row) => {
        return row.event_issue_types?.map(type => type.name).sort().join(', ');
    };

    const renderInvoiceStatusColumn = (column, row) => (<div>{row[column]}</div>);

    const rowRenderer = (column, row) => {
        switch (column) {
            case 'id':
                return renderIdColumn(column, row);
            case 'match_id':
                return renderMatchIdColumn(column, row);
            case 'start_datetime':
                return renderStartDatetimeColumn(column, row);
            case 'category':
                return renderCategoryColumn(row);
            case 'content_category_level2':
                return renderContentCategoryColumn(row, 2);
            case 'tournament':
                return renderTournamentColumn(row);
            case 'content_category_level3':
                return renderContentCategoryColumn(row, 3);
            case 'competitors':
                return renderCompetitorsColumn(row);
            case 'event_status':
                return renderStatusColumn(column, row);
            case 'products':
                return renderProductsColumn(column, row);
            case 'bookings':
                return renderBookingsColumn(column, row);
            case 'origin_stream_status':
                return renderOriginStreamStatusColumn(column, row);
            case 'comment':
                return renderCommentColumn(column, row);
            case 'issue_types':
                return renderIssueTypesColumn(row);
            case column.includes('invoice_status'):
                return renderInvoiceStatusColumn(column, row);
            default:
                return null;
        }
    };

    const propertyLicenceEventsForTable = props.propertyLicenceEvents.map(el => {
        el = addHasMatchIdToStructure(el);
        const competitors = getCompetitorsNameFromEvent(el);
        let invoice_status_columns = {};

        el.products_invoice_status.forEach((productInvoiceStatus) => {
            Object.assign(invoice_status_columns, {
                [`invoice_status_${getProductShortNameWithOnlyLettersAndDigits(productInvoiceStatus.short_name)}`]: _get(productInvoiceStatus, 'invoice_status.name', ''),
            });
        });

        return {
            ...el,
            ...invoice_status_columns,
            competitorsSortValue: `${competitors !== undefinedCompetitorsText ? competitors : ''}${el.description}`,
        };
    });

    return (
        <div className='propertyLicenceEvents__table'>
            <Table
                className='mini'
                columns={columns}
                data={propertyLicenceEventsForTable}
                showLimitWarning={true}
                dataLimitWarning={10000}
                loading={loading}
                name='propertyLicenceEventsList'
                noDataText='No events found'
                rowRenderer={rowRenderer}
            />
        </div>
    );
};

PropertyLicenceEventsTable.defaultProps = {
    propertyLicenceEvents: [],
};

PropertyLicenceEventsTable.propTypes = {
    loading: PropTypes.bool.isRequired,
    propertyLicenceEvents: PropTypes.array,
    propertyLicenceTypeId: PropTypes.number.isRequired,
};

export default PropertyLicenceEventsTable;
