import {get as _get, isNull as _isNull, isUndefined as _isUndefined} from 'lodash';
import PropTypes from 'prop-types';
// eslint-disable-next-line import/order
import React from 'react';

/* eslint import/no-unresolved: 0 */
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {compose} from 'redux';
import {Segment} from 'semantic-ui-react';

import {gql, graphql} from 'react-apollo';
import HeaderRenderer from '@appComponents/HeaderRenderer';
import {FilterQueryWrapper, FilterUrlParamsWrapper} from '@appComponents/HOCFiltersQueryWrapper';
import {getSearchFilters} from '@utils/filters';
import mapModulesToProps from '@utils/mapModulesToProps';
import navigation from '@constants/navigation';
import {
    PROPERTY_LICENCE_TYPE_OTHER,
    PROPERTY_LICENCE_TYPE_SPORTRADAR,
} from '@constants/variables';
import * as propertyLicenceEventsGraphQL from '@graphql/propertyLicences/events';
import {sortDropdownOptionsAlphanumerically} from '@utils/sorters';
import {showConstraintsModal, skipSearch} from '@modules/propertyLicence/utils/constraintsTables';
import {getTodayDate, getTomorrowDate} from '@utils/date';

import {exportExcel as propertyLicenceEventsExportExcel} from '../utils/export/propertyLicenceEventsExcel';
import PropertyLicenceEventsTable from '../components/PropertyLicenceEventsTable';
import PropertyLicenceEventsFilters from '../components/PropertyLicenceEventsFilters';

export class PropertyLicenceEvents extends React.Component {
    static propTypes = {
        DataPropertyLicenceEvents: PropTypes.object,
        DataPropertyLicenceEventsFilters: PropTypes.object,
        filters: PropTypes.shape({
            contentCategoryLevel3: PropTypes.arrayOf(PropTypes.number),
            eventStatus: PropTypes.arrayOf(PropTypes.number),
            from: PropTypes.string,
            originStreamStatus: PropTypes.arrayOf(PropTypes.number),
            product: PropTypes.arrayOf(PropTypes.number),
            search: PropTypes.string,
            to: PropTypes.string,
            tournament: PropTypes.arrayOf(PropTypes.number),
            issueTypes: PropTypes.arrayOf(PropTypes.number),
        }),
        loadingRefetch: PropTypes.bool.isRequired,
        history: PropTypes.object.isRequired,
        match: PropTypes.object.isRequired,
        Menu: PropTypes.object.isRequired,
        propertyLicenceTypeId: PropTypes.number,
    };

    static defaultProps = {
        filters: {
            contentCategoryLevel3: [],
            eventStatus: [],
            from: getTodayDate(),
            to: getTomorrowDate(),
            originStreamStatus: [],
            product: [],
            search: null,
            tournament: [],
            issueTypes: [],
        },
    };

    constructor() {
        super();

        this.state = {
            contentCategoriesLevel3: [],
            eventStatuses: [],
            originStreamStatuses: [],
            products: [],
            propertyLicenceEvents: [],
            propertyLicenceTypeId: null,
            tournaments: [],
            issueTypes: [],
        };
    }

    componentWillMount() {
        this.props.Menu.storeMenu(navigation.mediaRights.key);
    }

    componentWillReceiveProps(nextProps) {
        if (_isNull(nextProps.propertyLicenceTypeId)) {
            return null;
        }

        const {
                DataPropertyLicenceEvents: {propertyLicenceEvents} = [],
                DataPropertyLicenceEventsFilters: {
                    propertyLicenceContents,
                    eventStatuses,
                    originStreamStatuses,
                    products,
                    eventIssueTypes,
                },
                propertyLicenceTypeId,
            } = nextProps,
            nextState = {};

        if (eventStatuses && this.state.eventStatuses !== []) {
            nextState.eventStatuses = eventStatuses.map((eventStatus) => ({
                key: eventStatus.value,
                text: eventStatus.text.capitalizeFirstLetter(),
                value: parseInt(eventStatus.value, 10),
            }));
        }

        if (originStreamStatuses && this.state.originStreamStatuses !== []) {
            nextState.originStreamStatuses = originStreamStatuses.map((originStreamStatus) => ({
                key: originStreamStatus.value,
                text: originStreamStatus.text,
                value: parseInt(originStreamStatus.value, 10),
            }));
        }

        if (products && this.state.products !== []) {
            nextState.products = products.map((product) => ({
                key: product.value,
                text: product.text,
                value: parseInt(product.value, 10),
            }));
        }

        if (propertyLicenceContents && this.state.contentCategories !== [] && this.state.tournaments !== []) {
            nextState.contentCategoriesLevel3 = sortDropdownOptionsAlphanumerically(
                propertyLicenceContents.filter(
                    propertyLicenceContent => null !== propertyLicenceContent.content_category
                ).map((propertyLicenceContent) => ({
                    key: propertyLicenceContent.content_category.id,
                    text: `
                        ${propertyLicenceContent.content_category.parent.name} 
                        >  
                        ${propertyLicenceContent.content_category.name}
                    `,
                    value: parseInt(propertyLicenceContent.content_category.id, 10),
                }))
            );

            nextState.tournaments = sortDropdownOptionsAlphanumerically(
                propertyLicenceContents.filter(
                    propertyLicenceContent => null !== propertyLicenceContent.tournament
                ).map((propertyLicenceContent) => ({
                    key: propertyLicenceContent.tournament.id,
                    text: `
                        ${propertyLicenceContent.tournament.tournament_category.name} 
                        > 
                        ${propertyLicenceContent.tournament.name}
                    `,
                    value: parseInt(propertyLicenceContent.tournament.id, 10),
                }))
            );
        }

        if(skipSearch(nextProps)) {
            nextState.propertyLicenceEvents = [];
        } else if (propertyLicenceEvents && this.state.propertyLicenceEvents !== propertyLicenceEvents) {
            nextState.propertyLicenceEvents = propertyLicenceEvents;
        }

        if (!_isNull(propertyLicenceTypeId) && this.state.propertyLicenceTypeId !== propertyLicenceTypeId) {
            nextState.propertyLicenceTypeId = propertyLicenceTypeId;
        }

        if (eventIssueTypes) {
            nextState.issueTypes = eventIssueTypes.map((issueType) => ({
                key: issueType.value,
                text: issueType.text,
                value: parseInt(issueType.value, 10),
            }));
        }

        this.setState(() => (nextState));
    }

    componentDidUpdate() {
        if (skipSearch(this.props)) {
            showConstraintsModal(this.props.filters);
        }
    }

    render() {
        if (_isNull(this.state.propertyLicenceTypeId)) {
            return <Segment basic loading={true}/>;
        }

        const loading = _get(this.props, 'DataPropertyLicenceEvents.loading') ||
                _get(this.props, 'DataPropertyLicenceEventsFilters.loading') ||
                this.props.loadingRefetch,
            filtersProps = {
                data: {
                    contentCategoriesLevel3: this.state.contentCategoriesLevel3,
                    eventStatuses: this.state.eventStatuses,
                    originStreamStatuses: this.state.originStreamStatuses,
                    products: this.state.products,
                    propertyLicenceTypeId: this.state.propertyLicenceTypeId,
                    tournaments: this.state.tournaments,
                    issueTypes: this.state.issueTypes,
                },
                loading: loading,
            };

        let {id: propertyLicenceId} = this.props.match.params;

        return (
            <div>
                <HeaderRenderer
                    exportExcelParams={propertyLicenceEventsExportExcel(
                        this.state.propertyLicenceEvents,
                        propertyLicenceId,
                        this.state.propertyLicenceTypeId
                    )}
                    filters={PropertyLicenceEventsFilters}
                    filtersProps={filtersProps}
                    loading={loading}
                />
                <div className='clearfix'/>
                <Segment basic className='--table'>
                    <PropertyLicenceEventsTable
                        loading={loading}
                        propertyLicenceEvents={this.state.propertyLicenceEvents}
                        propertyLicenceTypeId={this.state.propertyLicenceTypeId}
                    />
                </Segment>
            </div>
        );
    }
}

export const PropertyLicenceEventsFiltersQuery = gql(propertyLicenceEventsGraphQL.propertyLicenceEventsFiltersQuery);
export const PropertyLicenceEventsQuery = gql(propertyLicenceEventsGraphQL.propertyLicenceEventsQuery);

const PropertyLicenceEventsWithQuery = compose(
    graphql(PropertyLicenceEventsFiltersQuery, {
        name: 'DataPropertyLicenceEventsFilters',
        options: (props) => {
            return {
                fetchPolicy: 'cache-first',
                notifyOnNetworkStatusChange: true,
                variables: {
                    propertyLicence: parseInt(props.match.params.id, 10),
                },
            };
        },
    }),
    graphql(PropertyLicenceEventsQuery, {
        name: 'DataPropertyLicenceEvents',
        skip: props => _isNull(props.propertyLicenceTypeId) || skipSearch(props),
        options: (props) => {
            let defaultFilters = PropertyLicenceEvents.defaultProps.filters,
                filters = props.filters,
                contentCategoryLevel3 = filters.contentCategoryLevel3 || defaultFilters.contentCategoryLevel3,
                tournament = filters.tournament || defaultFilters.tournament;

            if (props.propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_OTHER) {
                contentCategoryLevel3 = defaultFilters.contentCategoryLevel3;
            }

            if (props.propertyLicenceTypeId !== PROPERTY_LICENCE_TYPE_SPORTRADAR) {
                tournament = defaultFilters.tournament;
            }

            return {
                fetchPolicy: 'network-only',
                notifyOnNetworkStatusChange: true,
                variables: {
                    search: filters.search || defaultFilters.search,
                    contentCategoryLevel3: contentCategoryLevel3,
                    eventStatus: filters.eventStatus || defaultFilters.eventStatus,
                    from: filters.from ? `${filters.from}:00` : `${defaultFilters.from}:00`,
                    originStreamStatus: filters.originStreamStatus || defaultFilters.originStreamStatus,
                    product: filters.product || defaultFilters.product,
                    propertyLicence: parseInt(props.match.params.id, 10),
                    to: !_isUndefined(filters.to) ? `${filters.to}:59` : null,
                    tournament: tournament,
                    issueTypes: filters.issueTypes || defaultFilters.issueTypes,
                },
            };
        },
    })
)(FilterQueryWrapper(PropertyLicenceEvents, {
    queryForRefresh: PropertyLicenceEventsQuery,
    filterUrls: ['propertyLicences.events.index'],
}));

const mapStateToProps = (state) => {
    let propertyLicence = state.app.entities.propertyLicence;

    return {
        filters: getSearchFilters(state, 'PropertyLicenceEvents', PropertyLicenceEvents.defaultProps.filters),
        propertyLicenceTypeId: (propertyLicence) ? parseInt(propertyLicence.property_licence_type_id, 10) : null,
    };
};

const mapDispatchToProps = mapModulesToProps(['Menu']);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(
    FilterUrlParamsWrapper(PropertyLicenceEventsWithQuery, PropertyLicenceEvents.defaultProps.filters)
));
