import {Icon, Popup} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';

const ClientPackageContentFormExternalIcon = ({ticketNumber}) => {
    return (
        <Popup
            content='Open in Jira'
            position='right center'
            trigger={
                <a href={`https://jira.sportradar.ag/browse/${ticketNumber.toUpperCase()}`} target='_blank' rel='noopener noreferrer'>
                    <Icon name='external'/>
                </a>
            }
        />
    );
};

ClientPackageContentFormExternalIcon.propTypes = {
    ticketNumber: PropTypes.string,
};

export default ClientPackageContentFormExternalIcon;
