import React from 'react';
import PropTypes from 'prop-types';

import Link from '@appComponents/Link';
import {Authorization} from '@appComponents/Authorization';
import {ButtonAdd} from '@appComponents/ButtonCollection';
import {CLIENT_VPL_LICENCE_KEY} from '@constants/resources';
import {SECURITY_PRIVILEGES_CREATE} from '@constants/variables';

const ClientVideoPlayerIndexButtons = ({clientId}) => {
    return (
        <Authorization authorization={{[CLIENT_VPL_LICENCE_KEY]: SECURITY_PRIVILEGES_CREATE}}>
            <Link name='clients.clientVideoPlayer.add' params={{id: clientId}}>
                <div className='content__buttons'>
                    <ButtonAdd>Add new licence key</ButtonAdd>
                </div>
            </Link>
        </Authorization>
    );
};

ClientVideoPlayerIndexButtons.propTypes = {
    clientId: PropTypes.number.isRequired,
};
export default ClientVideoPlayerIndexButtons;
