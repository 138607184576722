import React from 'react';
import {Label} from 'semantic-ui-react';

import {EVENT_NOT_PICKED, EVENT_STATUS_REVOKED} from '@constants/variables';
import {convertToInt} from '@utils/helpers';

export function isRedStatusLabel(statusId) {
    const statusIdInt = convertToInt(statusId);

    return [
        EVENT_STATUS_REVOKED,
        EVENT_NOT_PICKED,
    ].includes(statusIdInt);
}

export function renderEventStatusName (statusId, statusName, matchStatus = null, showFallback = true) {
    if(isRedStatusLabel(statusId) || matchStatus) {
        return <Label color={'red'} size={'mini'}>{statusName}</Label>;
    }

    return showFallback ? statusName : null;
}
